import React, { Component } from "react";
import { connect } from "react-redux";
import "../HomePage.scss";
// Import css files
import Slider from "react-slick";
import * as actions from "../../../store/actions";
import { withRouter } from "react-router";
import CurrencyFormat from "react-currency-format";
import { toInteger } from "lodash";
import { outOfProductContact } from "../../../services/userService";
import { toast } from "react-toastify";

class PerforatedPaper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrProduct: {
        arrPerforatedPapers: [],
        phone: "",
      },

      viewSize: window.innerWidth,

      amount: toInteger(1),
      form: "không chia",

      isSliderDragging: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.perforatedPapersRedux !== this.props.perforatedPapersRedux) {
      this.setState({
        arrPerforatedPapers: this.props.perforatedPapersRedux,
      });
    }
  }

  componentDidMount() {
    this.props.loadPerforatedPaper();
  }

  handleViewDetailProduct = (product) => {
    if (!this.state.isSliderDragging) {
    const name = product.name;
    const decodedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
      .replace(/[èéẹẻẽêềếệểễ]/g, "e")
      .replace(/[ìíịỉĩ]/g, "i")
      .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
      .replace(/[ùúụủũưừứựửữ]/g, "u")
      .replace(/[ỳýỵỷỹ]/g, "y")
      .replace(/đ/g, "d")
      .replace(/[^a-z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .trim(); // Trim leading and trailing spaces
    if (this.props.history) {
      this.props.history.push(`/chi-tiet-san-pham/${product.id}/${decodedName}`);
    }
    }
  };

  moveToPerforatedPaperPage = () => {
    if (this.props.history) {
      this.props.history.push(`/san-pham/giay-dllt`);
    }
  };

  addToCart = (item) => {
    let { amount, form } = this.state;
    const cartItem = {
      item,
      amount,
      form,
    };

    this.props.addToCart(cartItem);
  };

  buyNow = (item) => {
    let { amount, form } = this.state;
    const cartItem = {
      item,
      amount,
      form,
    };

    this.props.addToCart(cartItem);
    this.props.history.push(`/gio-hang`);
  };

  handleSliderBeforeChange = () => {
    this.setState({ isSliderDragging: true });
  };

  handleSliderAfterChange = () => {
    this.setState({ isSliderDragging: false });
  };

  handleSendPhoneEmail = async (item, index) => {
    let res = await outOfProductContact({
      phone: this.state.arrPerforatedPapers[index].phone,
      type: item.type,
      productName: item.name,
    });

    if (res && res.errCode === 0) {
      if (this.state.arrPerforatedPapers[index].phone.length > 10) {
        toast.error(
          `Số điện thoại của quý khách không phù hợp. Xin hãy nhập lại`
        );
      } else {
        toast.success("Yêu cầu đã gửi");
        this.setState({
          phone: "",
        });
      }
    } else {
      toast.error(`Vui lòng nhập số điện thoại để chúng tôi gọi lại quý khách`);
    }
  };

  onChangeInput = (event, productIndex) => {
    let copyState = { ...this.state };
    copyState.arrPerforatedPapers[productIndex].phone = event.target.value;
    this.setState({
      ...copyState,
    });
  };

  render() {
    let arrPerforatedPapers = this.state.arrPerforatedPapers;
    let phone = this.state.phone;
    let viewSize = this.state.viewSize;
    return (
      <div className="container-fluid product-section-container pb-3">
        <div className="container-fluid d-flex justify-content-between align-items-center pt-2 section-header-container">
          <h2 className="font-weight-bold font-size-serif">
            Giấy carbonless liên tục đục lỗ
          </h2>
          <button
            className="see-more"
            onClick={() => this.moveToPerforatedPaperPage()}
          >
            Xem thêm
          </button>
        </div>
        <div className="container-fluid product-slider-container">
          <Slider
            {...this.props.settings}
            beforeChange={this.handleSliderBeforeChange}
            afterChange={this.handleSliderAfterChange}
          >
            {arrPerforatedPapers &&
              arrPerforatedPapers.length > 0 &&
              arrPerforatedPapers.map((item, index) => {
                let imageBase64 = "";
                if (item.image) {
                  imageBase64 = new Buffer.from(item.image, "base64").toString(
                    "binary"
                  );
                }
                // let imageBase64 = item.image;
                let name = `${item.name}`;
                let price = `${item.price}`;
                let calType = `${item.describe}`;
                let type = `${item.type}`;
                let stockAmount = `${item.Stock.quantity}`;
                let unit = `${item.Stock.unit}`;
                return (
                  <div
                    className="container-fluid product-container"
                    key={index}
                  >
                    <div className="embed-responsive embed-responsive-1by1">
                      <img
                        loading="lazy"
                        src={imageBase64}
                        alt="prod-img"
                        className="embed-responsive-item img-fluid"
                        onClick={() => {
                          this.handleViewDetailProduct(item);
                        }}
                      />
                    </div>
                    <h5
                      className="font-weight-bold perforated-name"
                      onClick={() => {
                        this.handleViewDetailProduct(item);
                      }}
                    >
                      {name}
                    </h5>
                    <div className="price-add-cart">
                      {type !== "đl5l" && (
                        <div>
                          <div className="d-flex align-items-center product-price-container text-danger font-weight-bold justify-content-between">
                            <div className="price d-flex">
                              <CurrencyFormat
                                value={price}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              <div className="ml-1">{calType}</div>
                            </div>
                            {stockAmount === "0" && (
                              <div className="stock">Hết hàng</div>
                            )}
                            {stockAmount !== "0" && (
                              <div className="stock">
                                Tồn kho: {stockAmount}&nbsp;{unit}
                              </div>
                            )}
                          </div>

                          {stockAmount !== "0" && (
                            <div className="d-flex justify-content-between align-items-center buttons">
                              <button
                                className="add-cart-button"
                                onClick={() => this.addToCart(item)}
                              >
                                <i className="fas fa-shopping-cart"></i>
                                <span className="ml-1 add-cart">
                                  Thêm vào giỏ hàng
                                </span>
                              </button>

                              <button
                                className="add-cart-button"
                                onClick={() => this.buyNow(item)}
                              >
                                <span className="ml-1">Mua ngay</span>
                              </button>
                            </div>
                          )}

                          {stockAmount === "0" && (
                            <div className="request-contact">
                              <div className="request-form">
                                <input
                                  className="col-12"
                                  type="tel"
                                  placeholder={
                                    viewSize >= 1024
                                      ? "Quý khách muốn mua hàng xin nhập SĐT"
                                      : "Nhập SĐT"
                                  }
                                  pattern="[0-9]{10}"
                                  required
                                  value={phone}
                                  onChange={(event) => {
                                    this.onChangeInput(event, index);
                                  }}
                                ></input>
                              </div>

                              <button
                                className="request-button col-12"
                                onClick={() => {
                                  this.handleSendPhoneEmail(item, index);
                                }}
                              >
                                Gửi yêu cầu mua hàng
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {type === "đl5l" && (
                        <div className="product-price-container text-danger text-uppercase font-weight-bold">
                          Liên hệ đặt hàng
                        </div>
                      )}
                      {/* {type !== "đl5l" && (
                        <div className="d-flex justify-content-between align-items-center buttons">
                          <button
                            className="add-cart-button"
                            onClick={() => this.addToCart(item)}
                          >
                            <i className="fas fa-shopping-cart"></i>
                            <span className="ml-1 add-cart">
                              Thêm vào giỏ hàng
                            </span>
                          </button>

                          <button
                            className="add-cart-button"
                            onClick={() => this.buyNow(item)}
                          >
                            <span className="ml-1">Mua ngay</span>
                          </button>
                        </div>
                      )} */}
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    perforatedPapersRedux: state.product.perforatedPapers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPerforatedPaper: () => dispatch(actions.fetchPerforatedPaper()),
    addToCart: (cartItem) => dispatch(actions.addToCart(cartItem)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PerforatedPaper)
);
