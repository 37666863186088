import React, { Component } from "react";
import { connect } from "react-redux";
import HomeHeader from "../../HomePage/HomeHeader";
import Tab from "../../HomePage/Section/Tab";
import HomeFooter from "../../HomePage/HomeFooter";
import LastFooter from "../../HomePage/LastFooter";
import { withRouter } from "react-router";
import "./NewsPage.scss";
import * as actions from "../../../store/actions";

class NewsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrNews: [],
    };
  }

  async componentDidMount() {
    this.props.loadAllDetailNews();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allNewsPageRedux !== this.props.allNewsPageRedux) {
      this.setState({
        arrNews: this.props.allNewsPageRedux,
      });
    }
  }

  toNewsDetail = (news) => {
      const name = news.name;
    const decodedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
      .replace(/[èéẹẻẽêềếệểễ]/g, "e")
      .replace(/[ìíịỉĩ]/g, "i")
      .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
      .replace(/[ùúụủũưừứựửữ]/g, "u")
      .replace(/[ỳýỵỷỹ]/g, "y")
      .replace(/đ/g, "d")
      .replace(/[^a-z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .trim(); // Trim leading and trailing spaces
    if (this.props.history) {
      this.props.history.push(`/chi-tiet-tin-tuc/${news.id}/${decodedName}`);
    }
  };

  toNewsReplicaDetail = () => {
    if (this.props.history) {
      this.props.history.push(`/hoi-nghi-drupa`);
    }
  };
  render() {
    let arrNews = this.state.arrNews;
    return (
      <>
        <HomeHeader />
        <Tab />
        <div
          className="container-fluid d-flex flex-column news-container"
          style={{ backgroundColor: "#fafafa" }}
        >
          <h4 className="text-center text-uppercase font-weight-bold my-1">
            Tin tức
          </h4>
          {arrNews &&
            arrNews.length > 0 &&
            arrNews.map((item, index) => {
              let imageBase64 = "";
              if (item.image) {
                imageBase64 = new Buffer.from(item.image, "base64").toString(
                  "binary"
                );
              }
              let name = `${item.name}`;
              let description = `${item.Markdown.description}`;
              return (
                <div
                  key={index}
                  className="d-flex mb-5 news-nav"
                  onClick={() => this.toNewsDetail(item)}
                >
                  <div className="img-container embed-responsive embed-responsive-21by9">
                    <img
                      loading="lazy"
                      src={imageBase64}
                      alt="prod-img"
                      className="embed-responsive-item img-fluid"
                    />
                  </div>
                  <div
                    className="detail-container ml-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <h5 className="text-left">{name}</h5>
                    <span>{description}</span>
                    <span
                      className="text-primary"
                      onClick={() => this.toNewsDetail(item)}
                      style={{ cursor: "pointer" }}
                    >
                      Xem thêm
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <HomeFooter />
        <LastFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allNewsPageRedux: state.news.allDetailNews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllDetailNews: () => dispatch(actions.fetchAllDetailNews()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsPage)
);
