import actionTypes from "./actionTypes";
import {
  createNewPopupFunc,
  getAllPopup,
  deletePopupFunc,
  editPopupFunc,
  getAllPopupPageService,
} from "../../services/popupService";
import { toast } from "react-toastify";

export const createNewPopup = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await createNewPopupFunc(data);
      if (res && res.errCode === 0) {
        toast.success("Tạo Popup thành công");
        dispatch(savePopupSuccess());
        dispatch(fetchAllPopupStart());
      } else {
        dispatch(savePopupFailed());
      }
    } catch (e) {
      dispatch(savePopupFailed());
      console.log("savePopupFailed error", e);
    }
  };
};

export const savePopupSuccess = () => ({
  type: actionTypes.CREATE_POPUP_SUCCESS,
});

export const savePopupFailed = () => ({
  type: actionTypes.CREATE_POPUP_FAILED,
});

export const fetchAllPopupStart = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllPopup("ALL");
      if (res && res.errCode === 0) {
        dispatch(fetchAllPopupSuccess(res.Popups));
      } else {
        toast.error("Lấy Popup thất bại");
        dispatch(fetchAllPopupFailed());
      }
    } catch (e) {
      toast.error("Lấy Popup thất bại");
      dispatch(fetchAllPopupFailed());
      console.log("fetchAllPopupFailed error", e);
    }
  };
};

export const fetchAllPopupSuccess = (data) => ({
  type: actionTypes.FETCH_ALL_POPUP_SUCCESS,
  popups: data,
});

export const fetchAllPopupFailed = () => ({
  type: actionTypes.FETCH_ALL_POPUP_FAILED,
});

export const deletePopup = (PopupId) => {
  return async (dispatch, getState) => {
    try {
      let res = await deletePopupFunc(PopupId);
      if (res && res.errCode === 0) {
        toast.success("Xóa Popup thành công");
        dispatch(deletePopupSuccess());
        dispatch(fetchAllPopupStart());
      } else {
        toast.error("Xóa Popup thất bại");
        dispatch(deletePopupFailed());
      }
    } catch (e) {
      dispatch(deletePopupFailed());
      console.log("deletePopupFailed error", e);
    }
  };
};

export const deletePopupSuccess = () => ({
  type: actionTypes.DELETE_POPUP_SUCCESS,
});

export const deletePopupFailed = () => ({
  type: actionTypes.DELETE_POPUP_FAILED,
});

export const editPopup = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await editPopupFunc(data);
      if (res && res.errCode === 0) {
        toast.success("Cập nhật Popup thành công");
        dispatch(editPopupSuccess());
        dispatch(fetchAllPopupStart());
      } else {
        toast.error("Cập nhật Popup thất bại");
        dispatch(editPopupFailed());
      }
    } catch (e) {
      toast.error("Cập nhật Popup thất bại");
      dispatch(editPopupFailed());
      console.log("editPopupFailed error", e);
    }
  };
};

export const editPopupSuccess = () => ({
  type: actionTypes.EDIT_POPUP_SUCCESS,
});

export const editPopupFailed = () => ({
  type: actionTypes.EDIT_POPUP_FAILED,
});

export const fetchAllPopupPage = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllPopupPageService("");
      if (res && res.errCode === 0) {
        dispatch({
          type: actionTypes.FETCH_ALL_POPUP_PAGE_SUCCESS,
          dataAllPopup: res.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_ALL_POPUP_PAGE_FAILED,
        });
      }
    } catch (e) {
      console.log("Fetch all Popup failed: ", e);
      dispatch({
        type: actionTypes.FETCH_ALL_POPUP_PAGE_FAILED,
      });
    }
  };
};
