import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import "../HomePage.scss";
import { withRouter } from "react-router";
import Zalo from "../../../assets/zalo-logo.png";

function ContactWays() {
  function movePhone() {
    var elem = document.getElementById("phone");
    var width =
      1200 <= window.innerWidth
        ? 4
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 5
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 8
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 10
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 13
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 14
        : 15;

    var targetWidth =
      1200 <= window.innerWidth
        ? 15
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 20
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 30
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 33
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 40
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 45
        : 50;
    var id = setInterval(frame, 10);
    function frame() {
      if (width >= targetWidth) {
        clearInterval(id);
      } else {
        width++;
        elem.style.width = width + "%";
      }
    }
  }

  function reverseMovePhone() {
    var elem = document.getElementById("phone");
    var width =
       1200 <= window.innerWidth
        ? 15
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 20
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 30
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 33
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 40
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 45
        : 50;

    var targetWidth =
      1200 <= window.innerWidth
        ? 4
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 5
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 8
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 10
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 13
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 14
        : 15;
    var id = setInterval(frame, 10);

    function frame() {
      if (width <= targetWidth) {
        clearInterval(id);
      } else {
        width--;
        elem.style.width = width + "%";
      }
    }
  }

  function moveZalo() {
    var elem = document.getElementById("zalo");
    var width =
      1200 <= window.innerWidth
        ? 4
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 5
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 8
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 10
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 13
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 14
        : 15;

    var targetWidth =
       1200 <= window.innerWidth
        ? 15
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 20
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 30
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 33
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 40
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 45
        : 50;

    var id = setInterval(frame, 10);
    function frame() {
      if (width >= targetWidth) {
        clearInterval(id);
      } else {
        width++;
        elem.style.width = width + "%";
      }
    }
  }

  function reverseMoveZalo() {
    var elem = document.getElementById("zalo");
    var width =
       1200 <= window.innerWidth
        ? 15
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 20
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 30
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 33
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 40
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 45
        : 50;

    var targetWidth =
      1200 <= window.innerWidth
        ? 4
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 5
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 8
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 10
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 13
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 14
        : 15;
    var id = setInterval(frame, 10);

    function frame() {
      if (width <= targetWidth) {
        clearInterval(id);
      } else {
        width--;
        elem.style.width = width + "%";
      }
    }
  }

  const [showPhone, setShowPhone] = useState(false);
  const [showZalo, setShowZalo] = useState(false);
  return (
    <div className="contact-ways">
      <div
        className="phone d-flex justify-content-end"
        id="phone"
        onClick={() => {
          setShowPhone(!showPhone);
          {
            showPhone ? reverseMovePhone() : movePhone();
          }
        }}
      >
        <i className="fas fa-phone" />

        {showPhone && (
          <div className="d-flex flex-column phone-number">
            <a href="tel:+840903539536">0903.539.536</a>
            <a href="tel:#">0907.632.256</a>
          </div>
        )}
      </div>

      <div
        className="zalo d-flex justify-content-end"
        id="zalo"
        onClick={() => {
          setShowZalo(!showZalo);
          {
            showZalo ? reverseMoveZalo() : moveZalo();
          }
        }}
      >
        <img src={Zalo} alt="prod-img" className="zaloImg" />

        {showZalo && (
          <div className="d-flex flex-column zalo-number">
            <a href="https://zalo.me/0903539536" target="_blank">
              0903.539.536
            </a>
            <a href="https://zalo.me/0907632256" target="_blank">
              0907.632.256
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactWays;
