import actionTypes from "../actions/actionTypes";

const initialState = {
  isLoadingStock: false,
  stockProducts: [],
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STOCK_START:
      let copyState = { ...state };
      copyState.isLoadingStock = true;
      return {
        ...copyState,
      };

    case actionTypes.FETCH_STOCK_PRODUCTS_SUCCESS:
      state.stockProducts = action.stockProducts;
      return {
        ...state,
      };

    case actionTypes.FETCH_STOCK_PRODUCTS_FAILED:
      state.stockProducts = [];
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default stockReducer;
