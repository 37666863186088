import React, { Component, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import "./ProductRedux.scss";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import TableManageProduct from "./TableManageProduct";
import { CRUD_ACTIONS, CommonUtils } from "../../../../utils";
import { create } from "lodash";

class ProductRedux extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockArr: [],
      previewImgUrl: "",
      secondPreviewImgUrl: "",
      thirdPreviewImgUrl: "",

      name: "",
      price: "",
      discount: "0",
      stock: "",
      describe: "",
      type: "",
      image: "",
      secondImage: "",
      thirdImage: "",
    //   orderValue: "",

      mainSrc: "",
      action: "",
      productEditId: "",
    };
  }

  async componentDidMount() {
    this.props.getStockStart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.stockRedux !== this.props.stockRedux) {
      let arrStocks = this.props.stockRedux;
      this.setState({
        stockArr: this.props.stockRedux,
        stock: arrStocks && arrStocks.length > 0 ? arrStocks[0].keyMap : "",
      });
    }

    if (prevProps.listProducts !== this.props.listProducts) {
      let arrStocks = this.props.stockRedux;
      this.setState({
        name: "",
        price: "",
        discount: "0",
        describe: "",
        stock: arrStocks && arrStocks.length > 0 ? arrStocks[0].keyMap : "",
        type: "",
        // orderValue: "",
        createdEdit: "",
        image: "",
        secondImage: "",
        thirdImage: "",
        action: CRUD_ACTIONS.CREATE,
        previewImgUrl: "",
        secondPreviewImgUrl: "",
        thirdPreviewImgUrl: "",
      });
    }
  }

  handleOnChangeImage = async (event) => {
    let data = event.target.files;
    let file = data[0];
    if (file) {
      let base64 = await CommonUtils.getBase64(file);
      let objectUrl = URL.createObjectURL(file);
      this.setState({
        previewImgUrl: objectUrl,
        image: base64,
      });
    }
  };

  handleOnChangeSecondImage = async (event) => {
    let data = event.target.files;
    let file = data[0];
    if (file) {
      let secondBase64 = await CommonUtils.getBase64(file);
      let secondObjectUrl = URL.createObjectURL(file);
      this.setState({
        secondPreviewImgUrl: secondObjectUrl,
        secondImage: secondBase64,
      });
    }
  };

  handleOnChangeThirdImage = async (event) => {
    let data = event.target.files;
    let file = data[0];
    if (file) {
      let thirdBase64 = await CommonUtils.getBase64(file);
      let thirdObjectUrl = URL.createObjectURL(file);
      this.setState({
        thirdPreviewImgUrl: thirdObjectUrl,
        thirdImage: thirdBase64,
      });
    }
  };

  // handleOnChangeImage = (event) => {
  //   let imgUrl = event.target.value; // Assuming imgUrl is the Imgur URL input
  //   this.setState({
  //     previewImgUrl: imgUrl,
  //     image: imgUrl, // Set the image state to the Imgur URL directly
  //   });
  // };

  // handleOnChangeSecondImage = (event) => {
  //   let imgUrl = event.target.value; // Assuming imgUrl is the Imgur URL input
  //   this.setState({
  //     secondPreviewImgUrl: imgUrl,
  //     secondImage: imgUrl, // Set the image state to the Imgur URL directly
  //   });
  // };

  // handleOnChangeThirdImage = (event) => {
  //   let imgUrl = event.target.value; // Assuming imgUrl is the Imgur URL input
  //   this.setState({
  //     thirdPreviewImgUrl: imgUrl,
  //     thirdImage: imgUrl, // Set the image state to the Imgur URL directly
  //   });
  // };

  openPreviewImage = () => {
    if (!this.state.previewImgUrl) return;
    this.setState({
      isOpen: true,
      mainSrc: this.state.previewImgUrl,
    });
  };

  openPreviewSecondImage = () => {
    if (!this.state.secondPreviewImgUrl) return;
    this.setState({
      isOpen: true,
      mainSrc: this.state.secondPreviewImgUrl,
    });
  };

  openPreviewThirdImage = () => {
    if (!this.state.thirdPreviewImgUrl) return;
    this.setState({
      isOpen: true,
      mainSrc: this.state.thirdPreviewImgUrl,
    });
  };

  handleSaveProduct = () => {
    let isValid = this.checkValidateInput();
    if (isValid === false) return;

    let { action } = this.state;
    if (action === CRUD_ACTIONS.CREATE) {
      //fire redux create
      this.props.createNewProduct({
        name: this.state.name,
        price: this.state.price,
        discount: this.state.discount,
        describe: this.state.describe,
        stockId: this.state.stock,
        type: this.state.type,
        orderValue: this.state.orderValue,
        image: this.state.image,
        secondImage: this.state.secondImage,
        thirdImage: this.state.thirdImage,
      });
    }

    if (action === CRUD_ACTIONS.EDIT) {
      //fire redux edit
      this.props.editProductRedux({
        id: this.state.productEditId,
        name: this.state.name,
        price: this.state.price,
        discount: this.state.discount,
        describe: this.state.describe,
        stockId: this.state.stock,
        type: this.state.type,
        // orderValue: this.state.orderValue,
        createdEdit: this.state.createdEdit,
        image: this.state.image,
        secondImage: this.state.secondImage,
        thirdImage: this.state.thirdImage,
      });
    }
  };

  // handleSaveProduct = () => {
  //   let isValid = this.checkValidateInput();
  //   if (isValid === false) return;

  //   let { action } = this.state;
  //   if (action === CRUD_ACTIONS.CREATE) {
  //     // Fire redux create
  //     this.props.createNewProduct({
  //       name: this.state.name,
  //       price: this.state.price,
  //       discount: this.state.discount,
  //       describe: this.state.describe,
  //       stockId: this.state.stock,
  //       type: this.state.type,
  //       image: this.state.image, // Assuming this.state.image contains the Imgur URL
  //       secondImage: this.state.secondImage, // Assuming this.state.secondImage contains the Imgur URL
  //       thirdImage: this.state.thirdImage, // Assuming this.state.thirdImage contains the Imgur URL
  //     });
  //   }

  //   if (action === CRUD_ACTIONS.EDIT) {
  //     // Fire redux edit
  //     this.props.editProductRedux({
  //       id: this.state.productEditId,
  //       name: this.state.name,
  //       price: this.state.price,
  //       discount: this.state.discount,
  //       describe: this.state.describe,
  //       stockId: this.state.stock,
  //       type: this.state.type,
  //       createdEdit: this.state.createdEdit,
  //       image: this.state.image, // Assuming this.state.image contains the Imgur URL
  //       secondImage: this.state.secondImage, // Assuming this.state.secondImage contains the Imgur URL
  //       thirdImage: this.state.thirdImage, // Assuming this.state.thirdImage contains the Imgur URL
  //     });
  //   }
  // };

  checkValidateInput = () => {
    let isValid = true;
    let arrCheck = ["name", "price", "describe", "type"];
    for (let i = 0; i < arrCheck.length; i++) {
      if (!this.state[arrCheck[i]]) {
        isValid = false;
        alert("Thông tin không được để trống: " + arrCheck[i]);
        break;
      }
    }
    return isValid;
  };

  onChangeInput = (event, id) => {
    let copyState = { ...this.state };
    copyState[id] = event.target.value;
    this.setState({
      ...copyState,
    });
  };

  handleEditProductFromParent = (product) => {
    let imageBase64 = "";
    let secondImageBase64 = "";
    let thirdImageBase64 = "";
    if (product.image) {
      imageBase64 = new Buffer.from(product.image, "base64").toString("binary");
    }
    if (product.secondImage) {
      secondImageBase64 = new Buffer.from(
        product.secondImage,
        "secondBase64"
      ).toString("binary");
    }
    if (product.thirdImage) {
      thirdImageBase64 = new Buffer.from(
        product.thirdImage,
        "thirdBase64"
      ).toString("binary");
    }

    this.setState({
      name: product.name,
      price: product.price,
      discount: product.discount,
      describe: product.describe,
      stock: product.stockId,
      type: product.type,
      orderValue: product.orderValue,
      createdEdit: product.createdAt,
      image: imageBase64,
      secondImage: secondImageBase64,
      thirdImage: thirdImageBase64,
      previewImgUrl: imageBase64,
      secondPreviewImgUrl: secondImageBase64,
      thirdPreviewImgUrl: thirdImageBase64,
      action: CRUD_ACTIONS.EDIT,
      productEditId: product.id,
    });
  };

  render() {
    let stocks = this.state.stockArr;
    let {
      name,
      price,
      discount,
      describe,
      stock,
      type,
      image,
      secondImage,
      thirdImage,
    } = this.state;

    let createDay = new Date(this.state.createdEdit);
    let formatDay = createDay.toLocaleDateString("vi-VN");
    return (
      <div className="product-redux-container">
        <div className="title">Quản lý sản phẩm</div>
        <div className="body">
          <div className="container">
            <div className="row">
              <div className="col-12">Thêm sản phẩm</div>
              <div className="col-4">
                <label>Tên sản phẩm: </label>
                <input
                  className="form-control"
                  type="text"
                  value={name}
                  onChange={(event) => {
                    this.onChangeInput(event, "name");
                  }}
                  // disabled={this.state.action === CRUD_ACTIONS.EDIT ? true : false}
                />
              </div>

              <div className="col-4">
                <label>Giá tiền: </label>
                <input
                  className="form-control"
                  type="text"
                  value={price}
                  onChange={(event) => {
                    this.onChangeInput(event, "price");
                  }}
                />
              </div>

              <div className="col-4">
                <label>Giá khuyến mãi: </label>
                <input
                  className="form-control"
                  type="text"
                  value={discount}
                  onChange={(event) => {
                    this.onChangeInput(event, "discount");
                  }}
                />
              </div>

              <div className="col-3">
                <label>Loại giấy: </label>
                <select
                  className="form-control"
                  value={type || ""}
                  onChange={(event) => {
                    this.onChangeInput(event, "type");
                  }}
                >
                  <option value="" hidden>
                    Chọn loại
                  </option>
                  <option value="cuộn">Cuộn</option>
                  <option value="đllt">ĐLLT</option>
                  <option value="đl5l">ĐL5L</option>
                  <option value="photo">Photo</option>
                  <option value="bill air">Bill AIR</option>
                  <option value="bill sea">Bill SEA</option>
                  <option value="bill chuyển phát nhanh">
                    Bill chuyển phát nhanh
                  </option>
                  <option value="hóa đơn atm">Hóa đơn ATM</option>
                  <option value="hóa đơn edc">Hóa đơn EDC</option>
                  <option value="phiếu lương">Phiếu lương</option>
                  <option value="phiếu nhập kho">Phiếu nhập kho</option>
                  <option value="phiếu xuất kho">Phiếu xuất kho</option>
                  <option value="tem">Tem</option>
                  <option value="nhãn">Nhãn</option>
                </select>
              </div>

              <div className="col-3">
                <label>Kho chứa: </label>
                <select
                  className="form-control"
                  value={stock}
                  onChange={(event) => {
                    this.onChangeInput(event, "stock");
                  }}
                >
                  {stocks &&
                    stocks.length > 0 &&
                    stocks.map((item, index) => {
                      return (
                        <option key={index} value={item.keyMap}>
                          {item.valueVi}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-3">
                <label>Đơn vị tính: </label>
                <select
                  className="form-control"
                  value={describe || ""}
                  onChange={(event) => {
                    this.onChangeInput(event, "describe");
                  }}
                >
                  <option value="" hidden>
                    Chọn đơn vị tính
                  </option>
                  <option value="VNĐ/cuộn">VNĐ/cuộn</option>
                  <option value="VNĐ/thùng">VNĐ/thùng</option>
                  <option value="VNĐ/ream">VNĐ/ream</option>
                  <option value="Liên hệ">Liên hệ</option>
                </select>
              </div>

              <div className="col-3">
                <label>Hình ảnh 1: </label>
                <div className="preview-img-container">
                  <input
                    id="previewImg"
                    type="file"
                    hidden
                    onChange={(event) => this.handleOnChangeImage(event)}
                  />
                  <label className="label-upload" htmlFor="previewImg">
                    Tải ảnh<i className="fas fa-upload"></i>
                  </label>
                  <div
                    className="preview-img"
                    style={{
                      backgroundImage: `url(${this.state.previewImgUrl})`,
                    }}
                    onClick={() => this.openPreviewImage()}
                  ></div>
                </div>
              </div>

              <div className="col-3">
                <label>Hình ảnh 2: </label>
                <div className="preview-img-container">
                  <input
                    id="secondPreviewImg"
                    type="file"
                    hidden
                    onChange={(event) => this.handleOnChangeSecondImage(event)}
                  />
                  <label className="label-upload" htmlFor="secondPreviewImg">
                    Tải ảnh<i className="fas fa-upload"></i>
                  </label>
                  <div
                    className="preview-img"
                    style={{
                      backgroundImage: `url(${this.state.secondPreviewImgUrl})`,
                    }}
                    onClick={() => this.openPreviewSecondImage()}
                  ></div>
                </div>
              </div>

              <div className="col-3">
                <label>Hình ảnh 3: </label>
                <div className="preview-img-container">
                  <input
                    id="thirdPreviewImg"
                    type="file"
                    hidden
                    onChange={(event) => this.handleOnChangeThirdImage(event)}
                  />
                  <label className="label-upload" htmlFor="thirdPreviewImg">
                    Tải ảnh<i className="fas fa-upload"></i>
                  </label>
                  <div
                    className="preview-img"
                    style={{
                      backgroundImage: `url(${this.state.thirdPreviewImgUrl})`,
                    }}
                    onClick={() => this.openPreviewThirdImage()}
                  ></div>
                </div>
              </div>

              {/* <div className="col-3">
                <label>Hình ảnh 1: </label>
                <div className="preview-img-container">
                  <input
                    id="previewImg"
                    type="text"
                    value={image}
                    onChange={(event) => this.handleOnChangeImage(event)}
                    htmlFor="previewImg"
                  />
                  <label className="label-upload" htmlFor="previewImg">
                    Tải ảnh<i className="fas fa-upload"></i>
                  </label>
                  <div
                    className="preview-img"
                    style={{
                      backgroundImage: `url(${this.state.previewImgUrl})`,
                    }}
                    onClick={() => this.openPreviewImage()}
                  ></div>
                </div>
              </div>

              <div className="col-3">
                <label>Hình ảnh 2: </label>
                <div className="preview-img-container">
                  <input
                    id="secondPreviewImg"
                    type="text"
                    value={secondImage}
                    onChange={(event) => this.handleOnChangeSecondImage(event)}
                  />
                  <label className="label-upload" htmlFor="secondPreviewImg">
                    Tải ảnh<i className="fas fa-upload"></i>
                  </label>
                  <div
                    className="preview-img"
                    style={{
                      backgroundImage: `url(${this.state.secondPreviewImgUrl})`,
                    }}
                    onClick={() => this.openPreviewSecondImage()}
                  ></div>
                </div>
              </div>

              <div className="col-3">
                <label>Hình ảnh 3: </label>
                <div className="preview-img-container">
                  <input
                    id="thirdPreviewImg"
                    type="text"
                    value={thirdImage}
                    onChange={(event) => this.handleOnChangeThirdImage(event)}
                  />
                  <label className="label-upload" htmlFor="thirdPreviewImg">
                    Tải ảnh<i className="fas fa-upload"></i>
                  </label>
                  <div
                    className="preview-img"
                    style={{
                      backgroundImage: `url(${this.state.thirdPreviewImgUrl})`,
                    }}
                    onClick={() => this.openPreviewThirdImage()}
                  ></div>
                </div>
              </div> */}

              <div className="col-12 my-3">
                <button
                  className={
                    this.state.action === CRUD_ACTIONS.EDIT
                      ? "btn btn-warning"
                      : "btn btn-primary"
                  }
                  onClick={() => this.handleSaveProduct()}
                >
                  {this.state.action === CRUD_ACTIONS.EDIT ? "Cập nhật" : "Lưu"}
                </button>
              </div>

              <div className="col-12">
                <TableManageProduct
                  handleEditProductFromParent={this.handleEditProductFromParent}
                  action={this.state.action}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.isOpen === true && (
          <Lightbox
            mainSrc={this.state.mainSrc}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stockRedux: state.product.stocks,

    isLoadingStock: state.product.isLoadingStock,
    listProducts: state.product.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStockStart: () => dispatch(actions.fetchStockStart()),
    createNewProduct: (data) => dispatch(actions.createNewProduct(data)),
    fetchProductRedux: () => dispatch(actions.fetchAllProductStart()),
    editProductRedux: (data) => dispatch(actions.editProduct(data)),

    // processLogout: () => dispatch(actions.processLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductRedux);
