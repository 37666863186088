import React, { useState } from "react";
import SearchBar from "./SearchBar";
import { SearchResultList } from "./SearchResultList";
import { Modal } from "react-bootstrap";

function SearchBarApp({ show: initialShow }) {
  const [results, setResults] = useState([]);
  const [show, setShow] = useState(initialShow);

  function handleEsc(e) {
    if (e.key === "Escape") {
      setShow(false);
    }
  }

  function handleClose() {
    setShow(false);
  }

  function handleOpenSearch() {
    setShow(true);
  }
  return (
    <div className="SearchBarApp container-fluid">
      <SearchBar setResults={setResults} />
      <SearchResultList results={results} setResults={setResults} />
    </div>
    // <div>
    //   <div onClick={handleOpenSearch}>
    //     <i className="fas fa-search"></i>
    //   </div>

    //   <Modal show={show} onHide={handleClose}>
    //     <Modal.Body>
    //       <div>
    //         <div
    //           style={{ cursor: "pointer" }}
    //           variant="dark"
    //           onClick={handleClose}
    //           onKeyDown={(e) => handleEsc(e)}
    //         ></div>
    //         <div className="SearchBarApp container-fluid">
    //           <SearchBar setResults={setResults} />
    //           <SearchResultList results={results} />
    //         </div>
    //       </div>
    //     </Modal.Body>
    //   </Modal>
    // </div>
  );
}

export default SearchBarApp;
