import actionTypes from "./actionTypes";
import {
  saveStockProductService,
  getAllStocks,
  deleteStockProductFunc,
  editStockProductFunc,
} from "../../services/stockService";
import { toast } from "react-toastify";

export const saveStockProduct = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await saveStockProductService(data);
      if (res && res.errCode === 0) {
        toast.success("Tạo thông tin kho sản phẩm thành công");
        dispatch({
          type: actionTypes.SAVE_STOCK_PRODUCT_SUCCESS,
        });
        dispatch(fetchAllStockProductsStart());
      } else {
        toast.error("Tạo thông tin kho sản phẩm thất bại");
        dispatch({
          type: actionTypes.SAVE_STOCK_PRODUCT_FAILED,
        });
      }
    } catch (e) {
      toast.error("Tạo thông tin kho sản phẩm thất bại");
      dispatch({
        type: actionTypes.SAVE_STOCK_PRODUCT_FAILED,
      });
    }
  };
};

export const fetchAllStockProductsStart = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllStocks("ALL");
      if (res && res.errCode === 0) {
        dispatch({
          type: actionTypes.FETCH_STOCK_PRODUCTS_SUCCESS,
          stockProducts: res.stockProducts,
        });
      } else {
        toast.error("Lấy kho thất bại");
        dispatch({
          type: actionTypes.FETCH_STOCK_PRODUCTS_FAILED,
        });
      }
    } catch (e) {
      toast.error("Lấy kho thất bại");
      dispatch({
        type: actionTypes.FETCH_ALL_PRODUCT_FAILED,
      });
      console.log("fetchStockProductsFailed error", e);
    }
  };
};

export const deleteStockProduct = (inputId) => {
  return async (dispatch, getState) => {
    try {
      let res = await deleteStockProductFunc(inputId);
      if (res && res.errCode === 0) {
        toast.success("Xóa sản phẩm kho thành công");
        dispatch({
          type: actionTypes.DELETE_STOCK_PRODUCT_SUCCESS,
        });
        dispatch(fetchAllStockProductsStart());
      } else {
        toast.error("Xóa sản phẩm kho thất bại");
        dispatch({
          type: actionTypes.DELETE_STOCK_PRODUCT_FAILED,
        });
      }
    } catch (e) {
      toast.error("Xóa sản phẩm kho thất bại");
      dispatch({
        type: actionTypes.DELETE_STOCK_PRODUCT_FAILED,
      });
      console.log("deleteProductFailed error", e);
    }
  };
};

export const editStockProduct = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await editStockProductFunc(data);
      if (res && res.errCode === 0) {
        toast.success("Cập nhật sản phẩm kho thành công");
        dispatch({ type: actionTypes.EDIT_STOCK_PRODUCT_SUCCESS });
        dispatch(fetchAllStockProductsStart());
      } else {
        toast.error("Cập nhật sản phẩm kho thất bại");
        dispatch({ type: actionTypes.EDIT_STOCK_PRODUCT_FAILED });
      }
    } catch (e) {
      toast.error("Cập nhật sản phẩm kho thất bại");
      dispatch({ type: actionTypes.EDIT_STOCK_PRODUCT_FAILED });
      console.log("editProductFailed error", e);
    }
  };
};
