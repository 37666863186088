export const adminMenu = [
  {
    //quản lý người dùng
    name: "menu.admin.manage-user",
    menus: [
      {
        name: "menu.admin.manage-all-user",
        link: "/system/user",
      },
    ],
  },

  {
    //quản lý sản phẩm
    name: "menu.admin.manage-product",
    menus: [
      {
        name: "menu.admin.all-product",
        link: "/system/product",
      },
      {
        name: "menu.admin.describe-product",
        link: "/system/product-manage",
      },
    ],
  },

  {
    //quản lý chính sách
    name: "menu.admin.manage-policy",
    menus: [
      {
        name: "menu.admin.all-policy",
        link: "/system/policy",
      },
      {
        name: "menu.admin.edit-detail-policy",
        link: "/system/policy-manage",
      },
    ],
  },
  {
    //quản lí đơn hàng
    name: "menu.admin.manage-order",
    menus: [
      {
        name: "menu.admin.all-order",
        link: "/system/order",
      },
    ],
  },

  {
    //quản lí banner
    name: "menu.admin.manage-banner",
    menus: [
      {
        name: "menu.admin.all-banner",
        link: "/system/banner",
      },
    ],
  },

  {
    //quản lí popup
    name: "menu.admin.manage-popup",
    menus: [
      {
        name: "menu.admin.all-popup",
        link: "/system/popup",
      },
    ],
  },

  {
    //quản lí tin tức
    name: "menu.admin.manage-news",
    menus: [
      {
        name: "menu.admin.all-news",
        link: "/system/news",
      },

      {
        name: "menu.admin.detail-news",
        link: "/system/news-manage",
      },
    ],
  },

  {
    //quản lí kho
    name: "menu.admin.manage-stocks",
    menus: [
      {
        name: "menu.admin.stock-product",
        link: "/system/product-stock-manage",
      },
    ],
  },
];

export const employeeMenu = [
  {
    //quản lý sản phẩm
    name: "menu.admin.manage-product",
    menus: [
      {
        name: "menu.admin.all-product",
        link: "/system/product",
      },
      {
        name: "menu.admin.describe-product",
        link: "/system/product-manage",
      },
    ],
  },
  {
    //quản lí đơn hàng
    name: "menu.admin.manage-order",
    menus: [
      {
        name: "menu.admin.all-order",
        link: "/system/order",
      },
    ],
  },

  {
    //quản lí banner
    name: "menu.admin.manage-banner",
    menus: [
      {
        name: "menu.admin.all-banner",
        link: "/system/banner",
      },
    ],
  },

  {
    //quản lí popup
    name: "menu.admin.manage-popup",
    menus: [
      {
        name: "menu.admin.all-popup",
        link: "/system/popup",
      },
    ],
  },

  {
    //quản lí tin tức
    name: "menu.admin.manage-news",
    menus: [
      {
        name: "menu.admin.all-news",
        link: "/system/news",
      },

      {
        name: "menu.admin.detail-news",
        link: "/system/news-manage",
      },
    ],
  },
];
