import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import "./TableManageOrder.scss";
import "./DetailOrderAdmin";
import DetailOrderAdmin from "./DetailOrderAdmin";
import DetailOrderAdminEdit from "./DetailOrderAdminEdit";
import axios from "../../../../axios";
import { SaveToOrder } from "../../../../store/actions";
import { toInteger } from "lodash";
import { getDetailInforProduct } from "../../../../services/productService";
import Select from "react-select";

class TableManageOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderRedux: [],
      statusID: "",
      showModal: false,
      showEditModal: false,
      orderNumber: "",
      address: "",
      time: "",
      preTotal: 0,
      vat: 0,
      shipFee: 0,
      discount: 0,
      couponDiscount: 0,
      totalPrice: 0,
      fullName: "",
      address: "",
      emailAddress: "",
      notify: "",
      phoneNumber: "",
      payment: "",
      billPrinted: "",
      discount: 0,
      shipFee: 0,
      statusNum: 0,
      date: "",
      companyAddress: "",
      companyName: "",

      newOrderAmount: "",

      orderNumberSearch: "",
      companyNameSearch: "",
      searchedOrder: [],
      
      thType: "Trạng thái",
      statusArr: [],
    };
  }

  componentDidMount() {
    this.props.fetchOrderRedux();
    this.props.fetchStatusRedux();
    this.props.checkNewOrder();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.listOrders !== this.props.listOrders) {
      this.setState({
        orderRedux: this.props.listOrders,
      });
    }

    if (prevProps.listStatus !== this.props.listStatus) {
      this.setState({
        statusArr: this.props.listStatus,
      });
    }

    if (prevProps.searchedOrder !== this.props.searchedOrder) {
      this.setState({
        searchedOrder: this.props.searchedOrder,
      });
    }
  }
  calcShipFee(preTotal) {
    if (preTotal <= 5000000) {
      return 35000;
    }
    if (5000000 < preTotal) {
      if (10000000 < preTotal) {
        return 0;
      }
      return 20000;
    }
  }
  checkStatus(statusId, statusList) {
    const matchedItem = statusList.find((item) => item.keyMap === statusId);
    const valueVi = matchedItem ? matchedItem.valueVi : null;
    return valueVi;
  }
  handleDeleteOrder = (item) => {
    let orderInfo = {};
    orderInfo.id = item.id;
    orderInfo.orderNumber = item.orderNumber;
    this.props.deleteOrder(orderInfo);
  };

  handleCloseFromParent = () => {
    this.setState({
      showModal: false,
    });
  };

  handleCloseEditFromParent = () => {
    this.setState({
      showEditModal: false,
    });
  };

  open = (item) => {
    this.props.detailByNumber(item.orderNumber);
    const date = new Date(item.createdAt);
    const formattedDate = date.toLocaleDateString("vi-VN");

    this.setState({
      showModal: true,
      fullName: item.userName,
      address: item.address,
      phoneNumber: item.phoneNumber,
      companyName: item.companyName,
      companyAddress: item.companyAddress,
      taxNumber: item.taxNumber,
      emailAddress: item.emailAddress,
      notify: item.notify,

      preTotal: item.preTotal,
      shipFee: item.shipFee,
      discount: item.discount,
      couponDiscount: item.couponDiscount,
      totalPrice: item.totalPrice,
      payment: item.payment,
      orderNumber: item.orderNumber,
      vat: item.vat,
      date: formattedDate,
      billPrinted: item.billPrinted,
      statusID: item.statusID,
    });
  };

  openEdit = (item) => {
    this.props.detailByNumber(item.orderNumber);
    const date = new Date(item.createdAt);
    const formattedDate = date.toLocaleDateString("vi-VN");

    this.setState({
      showEditModal: true,
      fullName: item.userName,
      address: item.address,
      phoneNumber: item.phoneNumber,
      companyName: item.companyName,
      companyAddress: item.companyAddress,
      taxNumber: item.taxNumber,
      emailAddress: item.emailAddress,
      notify: item.notify,

      preTotal: item.preTotal,
      shipFee: item.shipFee,
      discount: item.discount,
      couponDiscount: item.couponDiscount,
      totalPrice: item.totalPrice,
      payment: item.payment,
      orderNumber: item.orderNumber,
      vat: item.vat,
      date: formattedDate,
      billPrinted: item.billPrinted,
      statusID: item.statusID,
    });
  };

  checkRoll = (value) => {
    if (value === "cuộn") {
      return true;
    } else {
      return false;
    }
  };

  // Inside the handleEditOrderStatus method of TableManageOrder component

  handleEditOrderStatus = async (item, e) => {
    // Call editOrderStatus after updating stock quantities
    await this.props.editOrderStatus({
      id: item.id,
      statusID: e.target.value,
    });
  };
  
  handleCheckOrderStatus = async (item, e) => {
    if (e.target.value === "S4") {
      let updatedStockDescriptions = [];

      // Iterate over listDetail to update product quantities
      for (const detailItem of this.props.listDetail) {
        let res = await getDetailInforProduct(detailItem.productID);
        if (res && res.errCode === 0 && res.data.Stock) {
          let stock = res.data.Stock;
          // Increase the quantity of product in stock by detailItem.amount
          stock.quantity += detailItem.amount;

          let stockObject = {
            id: detailItem.stockNumber,
            productId: detailItem.productID,
            quantity: stock.quantity,
          };

          updatedStockDescriptions.push(stockObject);
        }
      }

      // Update the stock quantities in the database using SaveToOrder action
      await this.props.SaveToOrder({
        stockDescription: updatedStockDescriptions,
        orderNumber: item.orderNumber,
      });
    }
  };

  searchByOrderNumber = (e) => {
    this.setState({
      orderNumberSearch: e.target.value,
    });
  };

  searchByCompanyName = (e) => {
    this.setState({
      companyNameSearch: e.target.value,
    });
  };

  filterOrders = (orderNumber, companyName) => {
    const { orderRedux } = this.state;
    const filteredOrders = orderRedux.filter((order) => {
      const matchOrderNumber = orderNumber
        ? order.orderNumber.toLowerCase().includes(orderNumber.toLowerCase())
        : true;

      const matchCompanyName = companyName
        ? order.companyName &&
          order.companyName.toLowerCase().includes(companyName.toLowerCase())
        : true;
      return matchOrderNumber && matchCompanyName;
    });
    return filteredOrders;
  };

  render() {
    let status = this.state.statusArr;
    let { orderNumberSearch, companyNameSearch, thType } = this.state;
    let filteredOrders = this.filterOrders(
      orderNumberSearch,
      companyNameSearch
    );
    
    let arrOrders = this.state.orderRedux;

    let item = {
      showModal: this.state.showModal,
      showEditModal: this.state.showEditModal,
      preTotal: this.state.preTotal,
      shipFee: this.state.shipFee,
      discount: this.state.discount,
      couponDiscount: this.state.couponDiscount,
      totalPrice: this.state.totalPrice,
      vat: this.state.vat,
      payment: this.state.payment,
      orderNumber: this.state.orderNumber,
      billPrinted: this.state.billPrinted,
      date: this.state.date,
      statusID: this.state.statusID,

      fullName: this.state.fullName,
      receiveAddress: this.state.address,
      phoneNumber: this.state.phoneNumber,
      companyName: this.state.companyName,
      companyAddress: this.state.companyAddress,
      taxNumber: this.state.taxNumber,
      emailAddress: this.state.emailAddress,
      notify: this.state.notify,
    };
    
    let customStyles = {
      control: (provided) => ({
        ...provided,
        width: "100%",
        height: "100%",
        padding: 0,
        margin: 0,
        border: "none",
        backgroundColor: "white",
        background: "white",
        color: "black",
      }),
      
      option: (provided, state) => ({
        ...provided,
        color: "black", // Color of text when selected and not selected
        backgroundColor: state.isSelected ? "blue" : "white", // Background color when selected and not selected
        "&:hover": {
        backgroundColor: "lightgray", // Background color on hover
        color: "black", // Text color on hover
    },
  }),
    };

    let statusOrder = [
      { value: "Trạng thái", label: "Trạng thái" },
      ...status.map((statusItem) => ({
        value: statusItem.keyMap,
        label: statusItem.valueVi,
      })),
    ]; // Define the order of values
    
    if (thType.toLowerCase() !== "trạng thái") {
      arrOrders = arrOrders.filter(
        (item) => item.statusID.toLowerCase() === thType.toLowerCase()
      );
    }

    return (
      <React.Fragment>
        <div className="input-wrapper col-6">
          <i className="fas fa-search"></i>
          <input
            type="text"
            placeholder="Tìm theo tên công ty"
            value={companyNameSearch}
            onChange={(e) => this.searchByCompanyName(e)}
          />
        </div>
        <div className="input-wrapper col-6">
          <i className="fas fa-search"></i>
          <input
            type="text"
            placeholder="Tìm theo mã đơn hàng"
            value={orderNumberSearch}
            onChange={(e) => this.searchByOrderNumber(e)}
          />
        </div>
        <table id="TableManageOrder">
          <tbody>
            <tr>
              <th>STT</th>
              <th>Mã HĐ</th>
              <th>Email</th>
              <th>Khách hàng</th>
              <th>SĐT</th>
              <th>Đ/c giao</th>
              <th>Công ty</th>
              <th>MST</th>
              <th>Ngày đặt</th>
              <th>Thanh toán</th>
              <th>In HĐ</th>
              <th>
                <Select
                  className="typeSelector"
                  styles={customStyles}
                  options={statusOrder}
                  value={statusOrder.find(option => option.value === thType)}
                  onChange={(selectedOption) =>
                    this.setState({ thType: selectedOption.value })
                  }
                />
              </th>
              <th>Hành động</th>
            </tr>
            {arrOrders &&
              arrOrders.length > 0 &&
              arrOrders.map((item, index) => {
                let orderDate = new Date(item.createdAt);
                let formattedOrderDate = orderDate.toLocaleDateString("vi-VN");
                let formattedOrderTime = orderDate.toLocaleTimeString("vi-VN");
                let reverseIndex = arrOrders.length - index;
                return (
                  <tr key={index}>
                    <td>{reverseIndex}</td>
                    <td>{item.orderNumber}</td>
                    <td>{item.emailAddress}</td>
                    <td>{item.userName}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.address}</td>
                    <td>{item.companyName}</td>
                    <td>{item.taxNumber}</td>
                    <td>
                      {formattedOrderDate}
                      <br />
                      {formattedOrderTime}
                    </td>

                    <td>{item.payment}</td>
                    <td>{item.billPrinted}</td>
                    <td>
                      <select
                        value={item.statusID}
                        onChange={async (e) => {
                          await this.handleEditOrderStatus(item, e);
                          await this.handleCheckOrderStatus(item, e);
                        }}
                      >
                        {status &&
                          status.length > 0 &&
                          status.map((statusItem, index) => {
                            const isDisabled =
                              statusItem.keyMap < item.statusID ||
                              item.statusID === "S3" ||
                              item.statusID === "S4";
                            return (
                              <option
                                key={index}
                                value={statusItem.keyMap}
                                disabled={isDisabled}
                              >
                                {statusItem.valueVi}
                              </option>
                            );
                          })}
                      </select>
                      <div className="text-danger">{item.notify}</div>
                    </td>
                    <td>
                    <button onClick={() => this.open(item)}>
                        <i className={"far fa-eye"}></i>
                    </button>
                      <button
                        onClick={() => this.openEdit(item)}
                        className="btn-edit"
                        disabled={
                          item.statusID === "S3"
                            ? true
                            : item.statusID === "S4"
                            ? true
                            : item.statusID === "S5"
                            ? true
                            : false
                        }
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </button>
                      <button
                        onClick={() => this.handleDeleteOrder(item)}
                        className="btn-delete"
                        disabled={
                          item.statusID === "S2"
                            ? true
                            : item.statusID === "S3"
                            ? true
                            : item.statusID === "S4"
                            ? true
                            : item.statusID === "S5"
                            ? true
                            : false
                        }
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {item.showModal && (
          <DetailOrderAdmin
            item={item}
            handleCloseFromParent={this.handleCloseFromParent}
          />
        )}

        {item.showEditModal && (
          <DetailOrderAdminEdit
            item={item}
            handleCloseEditFromParent={this.handleCloseEditFromParent}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listOrders: state.order.orders,
    listDetail: state.order.detailOrders,
    detailUser: state.order.userName,
    listStatus: state.order.statusList,
    searchedOrder: state.order.searchedOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderRedux: () => dispatch(actions.fetchOrderStart()),
    fetchStatusRedux: () => dispatch(actions.fetchStatusStart()),
    deleteOrder: (orderInfo) => dispatch(actions.deleteOrderStart(orderInfo)),
    detailByNumber: (orderNumber) =>
      dispatch(actions.detailByNumber(orderNumber)),
    getStatusStart: () => dispatch(actions.fetchStatusStart()),
    editOrderStatus: (data) => dispatch(actions.editOrderStatusFunc(data)),
    searchOrder: (orderNumber) =>
      dispatch(actions.searchOrderStart(orderNumber)),
    checkNewOrder: () => dispatch(actions.checkNewOrderFunc()),

    SaveToOrder: (cartItems) => dispatch(actions.SaveToOrder(cartItems)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableManageOrder);
