import React, { Component, Fragment, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import UserRedux from "../containers/System/Admin//User/UserRedux";
import Header from "../containers/Header/Header";
import ProductRedux from "../containers/System/Admin/Product/ProductRedux";
import ManageProduct from "../containers/System/Admin/Product/ManageProduct";
import ManageStockProduct from "../containers/System/Admin/Stock/ManageStockProduct";
import PolicyRedux from "../containers/System/Admin/Policy/PolicyRedux";
import ManagePolicy from "../containers/System/Admin/Policy/ManagePolicy";
// import FormRedux from '../containers/System/Admin/FormRedux';
import OrderRedux from "../containers/System/Admin/Order/OrderRedux";
import BannerRedux from "../containers/System/Admin/Banner/BannerRedux";
import NewsRedux from "../containers/System/Admin/News/NewsRedux";
import ManageNews from "../containers/System/Admin/News/ManageNews";
import PopupRedux from "../containers/System/Admin/Popup/PopupRedux";

class System extends Component {
  render() {
    // { this.props.isLoggedIn && <Header /> }

    const { systemMenuPath, isLoggedIn, ref } = this.props;
    return (
      <React.Fragment>
        {isLoggedIn && <Header />}
        <div className="system-container">
          <div className="system-list">
            <Switch>
              <Route path="/system/user" component={UserRedux} />
              <Route path="/system/product" component={ProductRedux} />
              <Route path="/system/product-manage" component={ManageProduct} />
              <Route
                path="/system/product-stock-manage"
                component={ManageStockProduct}
              />
              <Route path="/system/policy" component={PolicyRedux} />
              <Route path="/system/policy-manage" component={ManagePolicy} />
              {/* <Route path="/system/form-redux" component={FormRedux} /> */}
              <Route path="/system/order" component={OrderRedux} />
              <Route path="/system/banner" component={BannerRedux} />
              <Route path="/system/popup" component={PopupRedux} />
              <Route path="/system/news" component={NewsRedux} />
              <Route path="/system/news-manage" component={ManageNews} />
              <Route
                component={() => {
                  return <Redirect to={systemMenuPath} />;
                }}
              />
            </Switch>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// function System(systemMenuPath, isLoggedIn) {
//   const handlePersistorState = () => {
//     const { persistor } = this.props;
//     let { bootstrapped } = persistor.getState();
//     if (bootstrapped) {
//       if (this.props.onBeforeLift) {
//         Promise.resolve(this.props.onBeforeLift())
//           .then(() => this.setState({ bootstrapped: true }))
//           .catch(() => this.setState({ bootstrapped: true }));
//       } else {
//         this.setState({ bootstrapped: true });
//       }
//     }
//   };

//   const customScrollbarsRef = useRef(null);

//   const handleScrollToTop = () => {
//     if (customScrollbarsRef.current) {
//       customScrollbarsRef.current.scrollTop(0);
//     }
//   };

//   // useEffect(() => {
//   //   // Attach the scroll-to-top function to a click event
//   //   document.addEventListener("click", handleScrollToTop);

//   //   // Cleanup function to remove the event listener
//   //   return () => {
//   //     document.removeEventListener("click", handleScrollToTop);
//   //   };
//   // }, []);

//   const componentDidMount = () => {
//     handlePersistorState();
//   };

//   return (
//     <Fragment>
//       {isLoggedIn && <Header />}
//       <div className="system-container">
//         <div className="system-list">
//           <Switch>
//             <Route path="/system/user" component={UserRedux} />
//             <Route
//               path="/system/product"
//               component={ProductRedux}
//               handleScrollToTop={handleScrollToTop}
//             />
//             <Route path="/system/product-manage" component={ManageProduct} />
//             <Route
//               path="/system/product-stock-manage"
//               component={ManageStockProduct}
//             />
//             <Route path="/system/policy" component={PolicyRedux} />
//             <Route path="/system/policy-manage" component={ManagePolicy} />
//             {/* <Route path="/system/form-redux" component={FormRedux} /> */}
//             <Route path="/system/order" component={OrderRedux} />
//             <Route path="/system/banner" component={BannerRedux} />
//             <Route path="/system/popup" component={PopupRedux} />
//             <Route path="/system/news" component={NewsRedux} />
//             <Route path="/system/news-manage" component={ManageNews} />
//             <Route
//               component={() => {
//                 return <Redirect to={systemMenuPath} />;
//               }}
//             />
//           </Switch>
//         </div>
//       </div>
//     </Fragment>
//   );
// }

const mapStateToProps = (state) => {
  return {
    systemMenuPath: state.app.systemMenuPath,
    isLoggedIn: state.user.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
