import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import HomeHeader from "./HomeHeader";
import Tab from "./Section/Tab";
import Banner from "./Section/Banner";
import RollPaper from "./Section/RollPaper";
import PhotoPaper from "./Section/PhotoPaper";
import PerforatedPaper from "./Section/PerforatedPaper";
import ContactWays from "./Section/ContactWays";
import HomeFooter from "./HomeFooter";
import LastFooter from "./LastFooter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-chat-elements/dist/main.css";
import Popup from "./Section/Popup";
import bocongthuong from "../../assets/bocongthuong.jpg";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePolicyId: null,
    };
  }

  handlePolicySelect = (policyId) => {
    this.setState({ activePolicyId: policyId }); // Update the active policy state based on the selected policy ID
  };

  render() {
    let settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      dots: false,
      lazyLoad: true,
    };
    let inYear = new Date().getFullYear();

    return (
      <React.Fragment>
        <HomeHeader />
        <Popup />
        <Tab />
        <Banner />

        {/* <Introduction /> */}
        <RollPaper settings={settings} />
        <PerforatedPaper settings={settings} />
        <PhotoPaper settings={settings} />
        {/* <PrintedPaper settings={settings} /> */}
        <ContactWays />
        <HomeFooter onPolicySelect={this.handlePolicySelect} />
        <LastFooter />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
