import React, { Component } from "react";
import { connect } from "react-redux";
import "./TableManageStocks.scss";
import * as actions from "../../../../store/actions";
// import style manually
import "react-markdown-editor-lite/lib/index.css";
import Select from "react-select";

class TableManageStocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrStockProducts: [],
    };
  }

  componentDidMount() {
    this.props.fetchStockProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.stockProducts !== this.props.stockProducts) {
      this.setState({
        arrStockProducts: this.props.stockProducts,
      });
    }
  }

  handleDeleteStockProduct = (stockProduct) => {
    this.props.deleteStockProduct(stockProduct.id);
  };

  handleEditStockProduct = (stockProduct) => {
    this.props.handleEditStockProductFromParent(stockProduct);
  };

  // handleScrollToTop = () => {
  //   this.props.handleScrollToTopFromParent();
  // };

  render() {
    let customStyles = {
      control: (provided) => ({
        ...provided,
        width: "100%",
        height: "100%",
        padding: 0,
        margin: 0,
        border: "none",
        backgroundColor: "white",
        background: "white",
        color: "black",
      }),
    };

    let arrStockProducts = this.state.arrStockProducts;
    // if (this.state.thType.toLowerCase() !== "loại giấy") {
    //   arrProducts = arrProducts.filter(
    //     (item) => item.type.toLowerCase() === this.state.thType.toLowerCase()
    //   );
    // }
    // arrProducts.sort((a, b) => a.type.localeCompare(b.type));
    return (
      <React.Fragment>
        <table id="TableManageStock">
          <tbody>
            <tr>
              <th>Tên sản phẩm</th>
              <th>Mã sản phẩm</th>
              <th>Tồn kho</th>
              <th>Quy cách</th>
              <th>Ngày tạo</th>
              <th>Ngày update</th>
              <th>Hành động</th>
            </tr>

            {arrStockProducts &&
              arrStockProducts.length > 0 &&
              arrStockProducts.map((item, index) => {
                let orderCreateDate = new Date(item.createdAt);
                let formattedOrderCreateDate =
                  orderCreateDate.toLocaleDateString("vi-VN");
                let formattedOrderCreateTime =
                  orderCreateDate.toLocaleTimeString("vi-VN");

                let orderUpdateDate = new Date(item.updatedAt);
                let formattedOrderDate =
                  orderUpdateDate.toLocaleDateString("vi-VN");
                let formattedOrderTime =
                  orderUpdateDate.toLocaleTimeString("vi-VN");
                return (
                  <tr key={index}>
                    <td>{item.productName}</td>
                    <td>{item.productCode}</td>
                    <td>{item.quantity}</td>
                    <td>{item.unit}</td>
                    <td>
                      {formattedOrderCreateDate}
                      <br />
                      {formattedOrderCreateTime}
                    </td>
                    <td>
                      {formattedOrderDate}
                      <br />
                      {formattedOrderTime}
                    </td>
                    <td>
                      <button onClick={() => this.handleEditStockProduct(item)}>
                        <i className="fas fa-pencil-alt"></i>
                      </button>
                      <button
                        className="btn-delete"
                        onClick={() => this.handleDeleteStockProduct(item)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stockProducts: state.stock.stockProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStockProducts: () => dispatch(actions.fetchAllStockProductsStart()),
    deleteStockProduct: (id) => dispatch(actions.deleteStockProduct(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableManageStocks);
