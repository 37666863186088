import React, { Component, useState } from "react";
import { connect } from "react-redux";
import "./HomePage.scss";
import logo from "../../assets/LOGO LIEN SON DUP.png";
import { withRouter } from "react-router";
import * as actions from "../../store/actions";
import SearchBarApp from "./SearchBar/SearchBarApp";

class HomeHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: "",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cartItems !== this.props.cartItems) {
      this.setState({
        cartList: this.props.cartItems,
      });
      // Store cartList in local storage
      localStorage.setItem("cartNumber", this.props.cartItems.length);
    }
  }

  componentDidMount() {
    // Retrieve cartList from local storage
    const cartNumber = localStorage.getItem("cartNumber");
    if (cartNumber) {
      this.setState({
        cartList: this.props.cartItems,
      });
    }

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  moveToCart = () => {
    this.props.history.push(`/gio-hang`);
  };

  returnHome = () => {
    if (this.props.history) {
      this.props.history.push(`/trang-chu`);
    }
  };

  render() {
    let cartNumber = this.state.cartList.length;
    const leftContent =
      992 <= window.innerWidth
        ? "col-9 d-flex align-items-center p-0"
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? "col-8 d-flex align-items-center p-0"
        : 577 <= window.innerWidth && window.innerWidth <= 767
        ? "col-7 d-flex align-items-center p-0"
        : 320 <= window.innerWidth && window.innerWidth <= 576
        ? "col-7 d-flex align-items-center p-0"
        : "col-5 d-flex align-items-center p-0";

    const rightContent =
      992 <= window.innerWidth
        ? "to-options-button col-3 d-flex align-items-center p-0 justify-content-between"
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? "to-options-button col-4 d-flex align-items-center p-0 justify-content-between"
        : 577 <= window.innerWidth && window.innerWidth <= 767
        ? "to-options-button col-5 d-flex align-items-center p-0 justify-content-between"
        : 320 <= window.innerWidth && window.innerWidth <= 576
        ? "to-options-button col-5 d-flex align-items-center p-0 justify-content-between"
        : "to-options-button col-7 d-flex align-items-center p-0 justify-content-between";

    return (
      <React.Fragment>
        <div className="container-fluid home-header-container d-flex align-items-center justify-content-between">
          <div className={leftContent}>
            <img
              className="img-fluid co-logo"
              src={logo}
              alt="logo"
              onClick={() => this.returnHome()}
            />

            <div className="co-info container-fluid d-flex flex-column p-1">
              <h5 className="text-uppercase font-weight-bold">
              Công ty TNHH Giấy vi tính Liên Sơn
              </h5>
              <h6>Địa chỉ: 34 Nguyễn Bỉnh Khiêm, P.Đa Kao, Quận 1, TP.HCM</h6>
            </div>
          </div>

          <div className={rightContent}>
            <SearchBarApp />

            <div className="to-cart-button d-flex align-items-center">
              <i
                className="fas fa-shopping-cart"
                onClick={() => this.moveToCart()}
              ></i>
              <div className="ml-1">{cartNumber}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    lang: state.app.language,
    allProductPageRedux: state.product.allProductsPage,
    cartItems: state.cart.cartItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllProductPage: () => dispatch(actions.fetchAllProductPage()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeHeader)
);
