import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import HomeHeader from "../../HomePage/HomeHeader";
import HomeFooter from "../../HomePage/HomeFooter";
import "./DetailPolicy.scss";
import { getDetailInforPolicy } from "../../../services/policyService";
import { withRouter } from "react-router";
import * as actions from "../../../store/actions";
import Tab from "../../HomePage/Section/Tab";

class DetailPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailPolicy: {},
      arrAllPolicy: [],
    };
  }

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      let id = this.props.match.params.id;
      let res = await getDetailInforPolicy(id);
      if (res && res.errCode === 0) {
        this.setState({
          detailPolicy: res.data,
        });
      }
    }

    if (this.state.arrAllPolicy.length > 0) {
      // Set the first policy as active when the page starts
      this.handleSetActive(
        this.state.arrAllPolicy[this.props.match.params.id - 1]
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allPolicyPageRedux !== this.props.allPolicyPageRedux) {
      this.setState({
        arrAllPolicy: this.props.allPolicyPageRedux,
      });
    }

    if (this.props.match && this.props.match.params) {
      const { id: prevId } = prevProps.match.params;
      const { id } = this.props.match.params;

      // Check if the product ID or name has changed
      if (id !== prevId) {
        this.fetchPolicyDetails(id);
      }
    }
  }

  async fetchPolicyDetails(id) {
    let res = await getDetailInforPolicy(id);
    if (res && res.errCode === 0) {
      this.setState({
        detailPolicy: res.data,
      });
    }
  }

  handleViewDetailPolicy = (selectedPolicy) => {
    let updatedPolicies = this.state.arrAllPolicy.map((policy) => {
      if (policy.id === selectedPolicy.id) {
        return { ...policy, isActive: true };
      } else {
        return { ...policy, isActive: false };
      }
    });
    this.setState({
      arrAllPolicy: updatedPolicies,
    });

    const name = selectedPolicy.name;
    const decodedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
      .replace(/[èéẹẻẽêềếệểễ]/g, "e")
      .replace(/[ìíịỉĩ]/g, "i")
      .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
      .replace(/[ùúụủũưừứựửữ]/g, "u")
      .replace(/[ỳýỵỷỹ]/g, "y")
      .replace(/đ/g, "d")
      .replace(/[^a-z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .trim(); // Trim leading and trailing spaces
    if (this.props.history) {
      this.props.history.push(`/chi-tiet-chinh-sach/${selectedPolicy.id}/${decodedName}`);
    }
  };

  handleSetActive = (selectedPolicy) => {
    let updatedPolicies = this.state.arrAllPolicy.map((policy) => {
      if (policy.id === selectedPolicy.id) {
        return { ...policy, isActive: true };
      } else {
        return { ...policy, isActive: false };
      }
    });

    this.setState({
      arrAllPolicy: updatedPolicies,
    });
  };

  render() {
    let { detailPolicy, arrAllPolicy } = this.state;
    return (
      <>
        <HomeHeader />
        <Tab />
        <div className="container-fluid">
          <div className="policy-detail-container">
            <div className="policy-choice">
              {arrAllPolicy &&
                arrAllPolicy.length > 0 &&
                arrAllPolicy.map((item, index) => {
                  let name = `${item.name}`;
                  return (
                    <div
                      className={`policy ${
                        item.isActive ? "policy-active" : ""
                      }`}
                      key={index}
                      onClick={() => this.handleViewDetailPolicy(item)}
                      onChange={() => this.handleSetActive(item)}
                    >
                      <div className="policy-name">{name}</div>
                    </div>
                  );
                })}
            </div>
            <div className="policy-detail">
              <div className="policy-describe">
                {detailPolicy &&
                  detailPolicy.Markdown &&
                  detailPolicy.Markdown.contentHTML && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detailPolicy.Markdown.contentHTML,
                      }}
                    ></div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allPolicyPageRedux: state.policy.allPoliciesPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllPolicyPage: () => dispatch(actions.fetchAllPolicyPage()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailPolicy)
);
