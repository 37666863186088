import axios from "../axios";

// const handleLoginApi = (email, password) => {
//     return axios.post('/api/login', { email, password });
// }

const getAllPopup = (inputId) => {
  // template string
  return axios.get(`/api/get-all-popup?id=${inputId}`);
};

const createNewPopupFunc = (data) => {
  return axios.post("/api/create-new-popup", data);
};

const deletePopupFunc = (popupId) => {
  return axios.delete("/api/delete-popup", {
    data: {
      id: popupId,
    },
  });
};

const editPopupFunc = (inputData) => {
  return axios.put("/api/edit-popup", inputData);
};

const getAllPopupPageService = (limit) => {
  return axios.get(`/api/get-all-popup-page?limit=${limit}`);
};
export {
  getAllPopup,
  createNewPopupFunc,
  deletePopupFunc,
  editPopupFunc,
  getAllPopupPageService,
};
