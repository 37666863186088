import React, { Component, useState } from "react";
import { connect } from "react-redux";
import "../HomePage.scss";
import { withRouter } from "react-router";
import Zalo from "../../../assets/zalo-logo.png";

function ContactOrders() {
  function movePhone() {
    var elem = document.getElementById("phone");
    var width =
      1200 <= window.innerWidth
        ? 4
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 5
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 8
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 10
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 13
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 14
        : 15;

    var targetWidth =
      1200 <= window.innerWidth
        ? 20
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 30
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 40
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 45
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 50
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 55
        : 60;
    var id = setInterval(frame, 10);
    function frame() {
      if (width >= targetWidth) {
        clearInterval(id);
      } else {
        width++;
        elem.style.width = width + "%";
      }
    }
  }

  function reverseMovePhone() {
    var elem = document.getElementById("phone");
    var width =
      1200 <= window.innerWidth
        ? 20
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 30
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 40
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 45
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 50
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 55
        : 60;

    var targetWidth =
      1200 <= window.innerWidth
        ? 4
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 5
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 8
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 10
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 13
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 14
        : 15;
    var id = setInterval(frame, 10);

    function frame() {
      if (width <= targetWidth) {
        clearInterval(id);
      } else {
        width--;
        elem.style.width = width + "%";
      }
    }
  }

  function moveZalo() {
    var elem = document.getElementById("zalo");
    var width =
      1200 <= window.innerWidth
        ? 4
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 5
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 8
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 10
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 13
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 14
        : 15;

    var targetWidth =
      1200 <= window.innerWidth
        ? 20
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 30
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 40
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 45
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 50
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 55
        : 60;
    var id = setInterval(frame, 10);
    function frame() {
      if (width >= targetWidth) {
        clearInterval(id);
      } else {
        width++;
        elem.style.width = width + "%";
      }
    }
  }

  function reverseMoveZalo() {
    var elem = document.getElementById("zalo");
    var width =
      1200 <= window.innerWidth
        ? 20
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 30
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 40
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 45
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 50
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 55
        : 60;

    var targetWidth =
      1200 <= window.innerWidth
        ? 4
        : 992 <= window.innerWidth && window.innerWidth <= 1199
        ? 5
        : 768 <= window.innerWidth && window.innerWidth <= 991
        ? 8
        : 500 <= window.innerWidth && window.innerWidth <= 767
        ? 10
        : 400 <= window.innerWidth && window.innerWidth <= 499
        ? 13
        : 320 <= window.innerWidth && window.innerWidth <= 399
        ? 14
        : 15;
    var id = setInterval(frame, 10);

    function frame() {
      if (width <= targetWidth) {
        clearInterval(id);
      } else {
        width--;
        elem.style.width = width + "%";
      }
    }
  }

  const [showPhone, setShowPhone] = useState(false);
  const [showZalo, setShowZalo] = useState(false);
  return (
    <div className="contact-ways">
      <div
        className="phone d-flex justify-content-end"
        id="phone"
        onClick={() => {
          setShowPhone(!showPhone);
          {
            showPhone ? reverseMovePhone() : movePhone();
          }
        }}
      >
        <i className="fas fa-phone" />

        {showPhone && (
          <div className="d-flex flex-column phone-number">
            <a href="tel:+840903539536">Miền Nam: 0903.539.536</a>
            <a href="tel:+840979899903">Miền Bắc: 0979.899.903</a>
          </div>
        )}
      </div>

      <div
        className="zalo d-flex justify-content-end"
        id="zalo"
        onClick={() => {
          setShowZalo(!showZalo);
          {
            showZalo ? reverseMoveZalo() : moveZalo();
          }
        }}
      >
        <img src={Zalo} alt="prod-img" className="zaloImg" />

        {showZalo && (
          <div className="d-flex flex-column zalo-number">
            <a href="https://zalo.me/0903539536" target="_blank">
              Miền Nam: 0903.539.536
            </a>
            <a href="https://zalo.me/0979899903" target="_blank">
              Miền Bắc: 0979.899.903
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactOrders;
