import React, { useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { history } from "../redux";

import "./CustomScrollbars.scss";

const CustomScrollbars = ({ handleScrollToTop, children, ...props }) => {
  const scrollbarsRef = useRef(null);

  useEffect(() => {
    const handleRouteChange = () => {
      if (scrollbarsRef.current) {
        scrollbarsRef.current.scrollTop(0);
      }
    };

    const unsubscribe = history.listen(handleRouteChange);

    return () => unsubscribe();
  }, [history]); // Update dependency array to include history

  return (
    <Scrollbars
      ref={scrollbarsRef}
      onScroll={props.onScroll}
      onScrollFrame={props.onScrollFrame}
      onScrollStart={props.onScrollStart}
      onScrollStop={props.onScrollStop}
      onUpdate={props.onUpdate}
      renderView={props.renderView}
      renderTrackHorizontal={props.renderTrackHorizontal}
      renderTrackVertical={props.renderTrackVertical}
      renderThumbHorizontal={props.renderThumbHorizontal}
      renderThumbVertical={props.renderThumbVertical}
      autoHide
      autoHideTimeout={props.autoHideTimeout}
      autoHideDuration={props.autoHideDuration}
      thumbMinSize={props.thumbMinSize}
      universal={props.universal}
      {...props}
    >
      {children}
    </Scrollbars>
  );
};

export default CustomScrollbars;
