import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import "./TableManageOrder.scss";
import CurrencyFormat from "react-currency-format";
import logo from "../../../../assets/LOGO LIEN SON.png";

class DetailOrderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderRedux: [],
      statusArr: [],
      statusID: "",
      showModal: false,

      orderNumber: "",
      fullName: "",
      address: "",
      phoneNumber: "",
      companyName: "",
      companyAddress: "",
      taxNumber: "",
      notify: "",

      payment: "",
      billPrinted: "",
      time: "",
      preTotal: 0,
      vat: 0,
      shipFee: 0,
      discount: 0,
      couponDiscount: 0,
      totalPrice: 0,
      discount: 0,
      shipFee: 0,

      newOrders: [],
      statusID: 0,
    };
  }
  componentDidMount() {
    this.props.fetchOrderRedux();
    this.props.fetchStatusRedux();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.listOrders !== this.props.listOrders) {
      this.setState({
        orderRedux: this.props.listOrders,
      });
    }

    if (prevProps.listDetail !== this.props.listDetail) {
      this.setState({
        detailProduct: this.props.listDetail,
      });
    }

    if (prevProps.listStatus !== this.props.listStatus) {
      // let arrStatus = this.props.statusRedux;
      this.setState({
        statusArr: this.props.listStatus,
        // status: arrStatus && arrStatus.length > 0 ? arrStatus[0].keyMap : "",
      });
    }

    if (prevProps.item !== this.props.item) {
      this.setState({
        statusID: this.props.item.statusID,
      });
    }
  }

  componentDidMount() {
    document.body.addEventListener("keydown", this.handleEsc); // Add event listener for "keydown" on the document body
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleEsc); // Remove event listener when component is unmounted
  }

  calcShipFee(preTotal) {
    if (preTotal <= 5000000) {
      return 35000;
    }
    if (5000000 < preTotal) {
      if (10000000 < preTotal) {
        return 0;
      }
      return 20000;
    }
  }

  checkType = (value) => {
    if (value === "cuộn") {
      return true;
    }else if (value === "photo") {
      return true;
    }else {
      return false;
    }
  };

  handleClose = (e) => {
    this.props.handleCloseFromParent();
  };

  handleEsc = (e) => {
    if (e.key === "Escape") {
      this.props.handleCloseFromParent();
    }
  };

  render() {
    let arrDetail = this.props.listDetail;
    let item = this.props.item;
    let statusID = this.state.statusID;
    const totalAmount = arrDetail.reduce(
      (total, item) => total + item.amount,
      0
    );
    let totalWages = item.preTotal + item.vat - item.discount
    return (
      <React.Fragment>
        <div className="detail-order-container" id="order-admin">
          <div className="order-container">
            <div
              className="container-fluid d-flex flex-column mt-2 border border-dark order"
              style={{ maxWidth: "950px" }}
            >
              <div className="d-flex border-bottom border-dark order-header">
                <div className="d-flex justify-content-left content-left">
                  <div className="co-logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="d-flex flex-column justify-content-around ml-2 ">
                    <h5
                      className="font-weight-bold co-name"
                      style={{ color: "#354b9c" }}
                    >
                      CÔNG TY TNHH GIẤY VI TÍNH LIÊN SƠN
                    </h5>
                    <h6>Đ/c: 34 Nguyễn Bỉnh Khiêm, P.Đa Kao, Q.1, TP.HCM</h6>
                    <h6>
                      Điện thoại: (028) 39.100.555 - Hotline: 0903.539.536
                    </h6>
                  </div>
                </div>

                <div className="d-flex flex-column justify-content-around content-right">
                  <h4
                    className="font-weight-bold text-center"
                    style={{ color: "#354b9c" }}
                  >
                    PHIẾU GIAO HÀNG
                  </h4>
                  <h6 className="text-center">Ngày đặt hàng: {item.date}</h6>
                  <h6 className="text-center font-weight-bold">
                    Mã đơn hàng: {item.orderNumber}
                  </h6>
                </div>
              </div>
              {item.billPrinted === "Có" && (
                <div className="border-bottom border-dark export-info">
                  <div className="row text-danger d-flex">
                    <div className="col-9">
                      <h5 className="font-weight-bold">
                        THÔNG TIN XUẤT HÓA ĐƠN
                      </h5>
                    </div>
                    <div className="col-3 small text-left">
                      <h6 className="font-weight-bold">
                        Xuất hóa đơn VAT: {item.billPrinted}
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <h6>Tên công ty: <span>{item.companyName}</span></h6>
                    </div>
                    <div className="col-3 text-left">
                      <h6>MST: {item.taxNumber}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h6>Đ/c công ty: {item.companyAddress}</h6>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex flex-column cust-info">
                <div className="border-bottom border-dark">
                  <div className="row">
                    <div className="col-9">
                      <h6>Tên khách hàng: <span>{item.fullName}</span></h6>
                    </div>
                    <div className="col-3 text-left">
                      <h6>SĐT: {item.phoneNumber}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">E-mail: {item.emailAddress}</div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h6>Đ/c giao hàng: {item.receiveAddress}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {item.payment === "Tiền mặt" && (
                        <div>
                          <h6>
                            Phương thức thanh toán: {item.payment}{" "}
                            <span className="text-primary">
                              (Quý khách thanh toán cho người vận chuyển sau khi
                              nhận và kiểm tra hàng)
                            </span>
                          </h6>
                        </div>
                      )}

                      {item.payment === "Chuyển khoản" && (
                        <div>
                          <h6>
                            Phương thức thanh toán: {item.payment}{" "}
                            <span className="text-primary">
                              (Quý khách thanh toán theo STK 037-1001-888888 NH
                              Vietcombank - CN Tân Định theo cú pháp "Số điện
                              thoại _ Mã đơn hàng")
                            </span>
                          </h6>
                        </div>
                      )}
                      
                       {item.billPrinted === "Không" && (
                      <div className="col-12 text-danger">
                        Xuất hóa đơn VAT: {item.billPrinted}
                      </div>
                    )}
                    </div>
                  </div>
                  
                </div>
                <table className="product-table table-borderless table-sm">
                  {statusID === "S5" && (
                    <div className="canceled-text">
                      <h1>Đơn đã hủy</h1>
                    </div>
                  )}
                  <tbody className="border-bottom border-dark order-detail">
                    <tr className="text-center border-bottom border-dark">
                      <th scope="col">STT</th>
                      <th scope="col">Tên sản phẩm</th>
                      <th scope="col">SL</th>
                      <th scope="col">ĐVT</th>
                      <th scope="col" className="text-right">
                        Đơn giá
                      </th>
                      <th scope="col" className="text-right">
                        Thành tiền
                      </th>
                    </tr>
                    {Array.from({ length: 20 }).map((_, index) => {
                      const item = arrDetail && arrDetail[index];
                      if (item) {
                        const calType =
                          item.type === "cuộn"
                            ? "Cuộn"
                            : item.type === "đllt"
                            ? "Thùng"
                            : "Ream";
                        return (
                          <tr key={index} className="text-center">
                            <th scope="row" className="col-1">
                              {index + 1}
                            </th>
                            {this.checkType(item.type) && (
                              <td className="text-left col-6">
                                {item.productName}
                              </td>
                            )}
                            {!this.checkType(item.type) && (
                              <td className="text-left col-6">
                                {item.productName}({item.form})
                              </td>
                            )}
                            <td className="col-1">{item.amount}</td>
                            <td className="col-1">{calType}</td>
                            <td className="text-right col-1">
                              <CurrencyFormat
                                value={item.price}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                            <td className="text-right col-2">
                              <CurrencyFormat
                                value={item.price * item.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={index}>
                            <td className="blank"></td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>

                  <tbody className="border-bottom border-dark">
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right" scope="col">
                        Tổng:
                      </td>
                      <td scope="col">{totalAmount}</td>
                      <td colSpan={2}></td>
                      <td className="text-right" scope="col">
                        <CurrencyFormat
                          value={item.preTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>
                    
                    {item.discount !== 0 && (
                      <tr>
                        <td></td>
                        <td className="text-right text-danger">Chiết khấu:</td>
                        <td colSpan={3}></td>
                        <td className="text-right text-danger">
                          <span>-</span>
                          <CurrencyFormat
                            value={item.discount}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                    )}
                    
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right">VAT(8%):</td>
                      <td colSpan={3}></td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={item.vat}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>
                    
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right">Tổng tiền hàng:</td>
                      <td colSpan={3}></td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={totalWages}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="text-right">Phí giao hàng:</td>
                      <td colSpan={3}></td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={item.shipFee}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right small text-danger" colSpan={6}>
                        (Ngoài bán kính giao hàng 5km, chúng tôi sẽ liên lạc báo phí giao hàng)
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="text-right text-danger">Mã giảm giá:</td>
                      <td colSpan={3}></td>
                      <td className="text-right text-danger">
                        <span>-</span>
                        <CurrencyFormat
                          value={item.couponDiscount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="text-right text-uppercase">
                        <h5 className="font-weight-bold">
                          Tổng tiền thanh toán:
                        </h5>
                      </td>
                      <td colSpan={2}></td>
                      <td className="text-right" colSpan={2}>
                        <h5 className="font-weight-bold">
                          <CurrencyFormat
                            value={item.totalPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            className="total-value"
                          />
                          &nbsp; VNĐ
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-center">
                  <div className="text-uppercase">
                    <h5 className="font-weight-bold">
                      Xin cảm ơn quý khách đã mua hàng
                    </h5>
                  </div>
                  <div className="caution small">
                    (Sản phẩm chỉ được đổi trả trong vòng 3 ngày kể từ ngày nhận
                    hàng và hóa đơn VAT sẽ được xuất cho quý khách)
                  </div>
                </div>
              </div>
            </div>

            <div
              className="container-fluid sign d-flex"
              style={{ maxWidth: "950px" }}
            >
              <div className="col">
                <p className="text-center">Người mua hàng</p>
                <p className="text-center">(Ký, ghi rõ họ tên)</p>
                <div className="signspace"></div>
                <p className="text-left">Ngày:</p>
              </div>
              
              <div className="col">
                <p className="text-center">Người giao hàng</p>
                <p className="text-center">(Ký, ghi rõ họ tên)</p>
                <div className="signspace"></div>
                <p className="text-left">Ngày:</p>
              </div>

              <div className="col">
                <p className="text-center">Người bán hàng</p>
                <p className="text-center">(Ký, ghi rõ họ tên)</p>
                <div className="signspace"></div>
                <p className="text-left">Ngày:</p>
              </div>
            </div>
            
            <div
              className="container-fluid sign mb-1"
              style={{ maxWidth: "950px" }}
            >
              <div className="col">
                <p className="text-left">Ghi chú:</p>
                <div className="signspace">
                  <h5 className="text-danger font-weight-bold">{item.notify}</h5>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <div
          className="d-flex justify-content-center option-admin"
          style={{ maxWidth: "790px" }}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => window.print()}
          >
            In hóa đơn
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.handleClose()}
            onKeyDown={(e) => this.handleEsc(e)}
          >
            Đóng
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listOrders: state.order.orders,
    listDetail: state.order.detailOrders,
    detailUser: state.order.userName,
    listStatus: state.order.statusList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderRedux: () => dispatch(actions.fetchOrderStart()),
    fetchStatusRedux: () => dispatch(actions.fetchStatusStart()),
    deleteOrder: (orderInfo) => dispatch(actions.deleteOrderStart(orderInfo)),
    detailByNumber: (orderNumber) =>
      dispatch(actions.detailByNumber(orderNumber)),

    getStatusStart: () => dispatch(actions.fetchStatusStart()),
    editOrderStatus: (data) => dispatch(actions.editOrderStatusFunc(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailOrderAdmin);
