import React, { Component } from "react";
import { connect } from "react-redux";
// import "./TableManagePopup.scss";
import * as actions from "../../../../store/actions";

// Finish!

class TableManagePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PopupsRedux: [],
    };
  }

  componentDidMount() {
    this.props.fetchPopupRedux();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.listPopups !== this.props.listPopups) {
      this.setState({
        PopupsRedux: this.props.listPopups,
      });
    }
  }

  handleDeletePopup = (Popup) => {
    this.props.deletePopupRedux(Popup.id);
  };

  handleEditPopup = (Popup) => {
    this.props.handleEditPopupFromParent(Popup);
  };

  render() {
    let arrPopups = this.state.PopupsRedux;
    return (
      <React.Fragment>
        <table id="TableManagePopup" className="table">
          <tbody>
            <tr>
              <th>Tên Popup</th>
              <th>Ngày tạo</th>
              <th>Ngày cập nhật</th>
              <th>Hành động</th>
            </tr>

            {arrPopups &&
              arrPopups.length > 0 &&
              arrPopups.map((item, index) => {
                let createdDate = new Date(item.createdAt);
                let updatedDate = new Date(item.updatedAt);
                let formattedCreatedDate =
                  createdDate.toLocaleDateString("vi-VN");
                let formattedCreatedTime =
                  createdDate.toLocaleTimeString("vi-VN");
                let formattedUpdatedDate =
                  updatedDate.toLocaleDateString("vi-VN");
                let formattedUpdatedTime =
                  updatedDate.toLocaleTimeString("vi-VN");
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {formattedCreatedDate}
                      <br />
                      {formattedCreatedTime}
                    </td>
                    <td>
                      {formattedUpdatedDate}
                      <br />
                      {formattedUpdatedTime}
                    </td>
                    <td>
                      <button
                        ref={this.myRef}
                        onClick={() => this.handleEditPopup(item)}
                        className="btn-edit"
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </button>
                      <button
                        onClick={() => this.handleDeletePopup(item)}
                        className="btn-delete"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listPopups: state.popup.popups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPopupRedux: () => dispatch(actions.fetchAllPopupStart()),
    deletePopupRedux: (id) => dispatch(actions.deletePopup(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableManagePopup);
