import React, { Component } from "react";
import { connect } from "react-redux";
import HomeHeader from "../../HomePage/HomeHeader";
import Tab from "../../HomePage/Section/Tab";
import HomeFooter from "../../HomePage/HomeFooter";
import "./DetailProduct.scss";
import { getDetailInforProduct } from "../../../services/productService";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import { sendRequestContactProduct } from "../../../services/userService";
import { outOfProductContact } from "../../../services/userService";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import * as actions from "../../../store/actions";
import { toInteger } from "lodash";
import "react-image-lightbox/style.css";
import { CommonUtils } from "../../../utils";
import RollPaper from "../../HomePage/Section/RollPaper";
import PhotoPaper from "../../HomePage/Section/PhotoPaper";
import PerforatedPaper from "../../HomePage/Section/PerforatedPaper";
import ContactWays from "../../HomePage/Section/ContactWays";
import PopupForm from "./PopupForm";

class DetailProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailProduct: [],
      amount: toInteger(1),
      mainImg: "",
      form: "không chia",

      email: "",
      name: "",
      company: "",
      phone: "",
      content: "",
      previewImgUrl: "",
      image: "",
    };
  }

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      let id = this.props.match.params.id;
      let res = await getDetailInforProduct(id);
      if (res && res.errCode === 0) {
        this.setState({
          detailProduct: res.data,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match && this.props.match.params) {
      const { id: prevId } = prevProps.match.params;
      const { id } = this.props.match.params;

      // Check if the product ID or name has changed
      if (id !== prevId) {
        this.setState({
          form: "không chia",
          detailProduct: this.fetchProductDetails(id),
          mainImg: "",
          amount: 1,
        });
      }
    }
  }

  async fetchProductDetails(id, name) {
    let res = await getDetailInforProduct(id);
    if (res && res.errCode === 0) {
      this.setState({
        detailProduct: res.data,
      });
    }
  }

  handleChangeCustom = (event) => {
    this.setState({
      form: event.target.value,
    });
  };

  handleMoveToCart = () => {
    let { detailProduct, amount, form } = this.state;
    if (!amount) {
      toast.error("Số lượng sản phẩm không được để trống");
    } else if (amount === 0) {
      toast.error("Số lượng sản phẩm không được bằng 0");
    } else {
      const cartItem = {
        detailProduct,
        amount,
        form,
      };
      this.props.addToCart(cartItem);
    }
  };

  handleBuyNow = () => {
    let { detailProduct, amount, form } = this.state;
    const cartItem = {
      detailProduct,
      amount,
      form,
    };

    this.props.addToCart(cartItem);
    this.props.history.push(`/gio-hang`);
  };

  onChangeInput = (event, id) => {
    let copyState = { ...this.state };
    copyState[id] = event.target.value;
    this.setState({
      ...copyState,
    });
  };

  handleChangeImage = (e) => {
    this.setState({
      mainImg: e.target.src,
    });
  };

  incNum = () => {
    let amount = this.state.amount;
    this.setState({
      amount: amount + 1,
    });
  };
  decNum = () => {
    let amount = this.state.amount;
    if (amount > 1) {
      this.setState({
        amount: amount - 1,
      });
    }
  };
  handleChange = (e) => {
    if (e.target.value >= 1) {
      this.setState({
        amount: toInteger(e.target.value),
      });
    } else {
      this.setState({
        amount: "",
      });
    }
  };
  handleOnChangeImage = async (event) => {
    let data = event.target.files;
    let file = data[0];
    if (file) {
      let base64 = await CommonUtils.getBase64(file);
      let objectUrl = URL.createObjectURL(file);
      this.setState({
        previewImgUrl: objectUrl,
        image: base64,
      });
    }
  };

  // handleOnChangeImage = async (event) => {
  //   let imgUrl = event.target.value; // Assuming imgUrl is the Imgur URL input
  //   this.setState({
  //     previewImgUrl: imgUrl,
  //     image: imgUrl, // Set the image state to the Imgur URL directly
  //   });
  // };

  handleSendPhoneEmail = async () => {
    let res = await sendRequestContactProduct({
      phone: this.state.phone,
      type: this.state.detailProduct.type,
      productName: this.state.detailProduct.name,
    });

    if (res && res.errCode === 0) {
      if (this.state.phone.length > 10) {
        toast.error(
          "Số điện thoại của quý khách không phù hợp. Xin hãy nhập lại"
        );
      } else {
        toast.success("Yêu cầu đã gửi");
        this.setState({ phone: "" });
      }
    } else {
      toast.error(`Vui lòng nhập số điện thoại để chúng tôi gọi lại quý khách`);
    }
  };

  handleOutOfProductEmail = async () => {
    let res = await outOfProductContact({
      phone: this.state.phone,
      type: this.state.detailProduct.type,
      productName: this.state.detailProduct.name,
    });

    if (res && res.errCode === 0) {
      toast.success("Yêu cầu đã gửi");
      this.setState({ phone: "" });
    } else {
      toast.error(`Vui lòng nhập số điện thoại để chúng tôi gọi lại quý khách`);
    }
  };

  render() {
    let { name, phone, content, company, image } = this.state;
    let { mainImg } = this.state;
    let { detailProduct } = this.state;
    let { amount, form } = this.state;
    let settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      dots: false,
    };

    return (
      <>
        <HomeHeader />
        <Tab />
        <div className="product-detail-container">
          <div className="product-image">
            <div className="product-image-main">
              {mainImg !== "" && <img src={mainImg} alt="mainImg" />}
              {mainImg === "" && (
                <img src={detailProduct.image} alt="mainImg" />
              )}
            </div>
            <div className="product-image-option">
              <div className="option-image">
                <img
                  src={detailProduct.image}
                  alt="firstImg"
                  onClick={(e) => this.handleChangeImage(e)}
                />
              </div>

              {detailProduct.secondImage && (
                <div className="option-image">
                  <img
                    src={detailProduct.secondImage}
                    alt="secondImg"
                    onClick={(e) => this.handleChangeImage(e)}
                  />
                </div>
              )}

              {detailProduct.thirdImage && (
                <div className="option-image">
                  <img
                    src={detailProduct.thirdImage}
                    alt="thirdImg"
                    onClick={(e) => this.handleChangeImage(e)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="product-detail">
            <div className="product-info">
              <div className="product-name">{detailProduct.name}</div>

              {detailProduct.type !== "đl5l" && (
                <div className="product-price-container d-flex">
                  <div className="product-price d-flex">
                    Đơn giá:&nbsp;
                    <CurrencyFormat
                      value={detailProduct.price}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    <div className="ml-1">{detailProduct.describe}</div>
                  </div>
                  {/* {!detailProduct.Stock && (
                    <div className="stock">Hết hàng</div>
                  )} */}
                  {detailProduct.Stock && (
                    <div className="stock">
                      {detailProduct.Stock.quantity === 0
                        ? "Hết hàng"
                        : `Tồn kho: ${detailProduct.Stock.quantity} ${detailProduct.Stock.unit}`}
                    </div>
                  )}
                </div>
              )}

              {detailProduct.type === "đl5l" && (
                <div className="product-price" style={{ color: "red" }}>
                  {detailProduct.describe}
                </div>
              )}

              <div className="product-describe">
                {detailProduct &&
                  detailProduct.Markdown &&
                  detailProduct.Markdown.contentHTML && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detailProduct.Markdown.contentHTML,
                      }}
                    ></div>
                  )}
              </div>
            </div>

            {detailProduct.type !== "đl5l" &&
              detailProduct.Stock &&
              detailProduct.Stock.quantity !== 0 && (
                <div className="product-selection">
                  {detailProduct.type === "đllt" && (
                    <div className="product-option">
                      <span>Chọn hình thức:</span>
                      <select
                        defaultValue="không chia"
                        onChange={this.handleChangeCustom}
                      >
                        <option value="không chia">Không chia</option>
                        <option value="chia đôi">Chia đôi</option>
                      </select>
                    </div>
                  )}

                  <div className="product-amount">
                    <span>Số lượng:</span>
                    <div className="amount">
                      <div className="decrease">
                        <button
                          type="button"
                          onClick={this.decNum}
                          disabled={amount ? false : true}
                        >
                          -
                        </button>
                      </div>
                      <input
                        type="number"
                        className="amount-number"
                        value={amount}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="0"
                      />
                      <div className="increase">
                        <button
                          type="button"
                          onClick={this.incNum}
                          disabled={amount ? false : true}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {detailProduct.type !== "đl5l" &&
              detailProduct.Stock &&
              detailProduct.Stock.quantity !== 0 && (
                <div className="cart-contact">
                  <button
                    className="button-add-cart"
                    onClick={this.handleMoveToCart}
                  >
                    <i className="fas fa-shopping-cart"></i>
                    <div className="order">Thêm vào giỏ hàng</div>
                  </button>

                  <button
                    className="button-add-cart"
                    onClick={this.handleBuyNow}
                  >
                    <div className="buy-now">Mua ngay</div>
                  </button>
                </div>
              )}

            {detailProduct.type !== "đl5l" &&
              detailProduct.Stock &&
              detailProduct.Stock.quantity === 0 && (
                <div className="request-contact">
                  <div className="request-form">
                    <input
                      type="tel"
                      placeholder="Quý khách có nhu cầu mua sản phẩm xin nhập SĐT tại đây"
                      pattern="[0-9]{10}"
                      required
                      value={phone}
                      onChange={(event) => {
                        this.onChangeInput(event, "phone");
                      }}
                    ></input>
                  </div>

                  <button
                    className="request-button"
                    onClick={() => {
                      this.handleOutOfProductEmail();
                    }}
                  >
                    Gửi yêu cầu
                  </button>
                </div>
              )}

            {detailProduct.type === "đl5l" && (
              <div className="popup-form">
                <PopupForm
                  productName={detailProduct.name}
                  productType={detailProduct.type}
                />
              </div>
            )}

            {detailProduct.type !== "đl5l" &&
              detailProduct.Stock &&
              detailProduct.Stock.quantity !== 0 && (
                <div className="request-contact">
                  <div className="request-form">
                    <input
                      type="tel"
                      placeholder="Quý khách có nhu cầu liên lạc xin nhập SĐT tại đây"
                      pattern="[0-9]{10}"
                      required
                      value={phone}
                      onChange={(event) => {
                        this.onChangeInput(event, "phone");
                      }}
                    ></input>
                  </div>

                  <button
                    className="request-button"
                    onClick={() => {
                      this.handleSendPhoneEmail();
                    }}
                  >
                    Gửi yêu cầu
                  </button>
                </div>
              )}

            {detailProduct.type === "đl5l" && (
              <div className="request-contact">
                <div className="request-form">
                  <input
                    type="tel"
                    placeholder="Quý khách có nhu cầu liên lạc xin nhập SĐT tại đây"
                    pattern="[0-9]{10}"
                    required
                    value={phone}
                    onChange={(event) => {
                      this.onChangeInput(event, "phone");
                    }}
                  ></input>
                </div>

                <button
                  className="request-button"
                  onClick={() => {
                    this.handleSendPhoneEmail();
                  }}
                >
                  Gửi yêu cầu
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          {detailProduct.type === "cuộn" && <RollPaper settings={settings} />}
          {detailProduct.type === "đllt" && (
            <PerforatedPaper settings={settings} />
          )}
          {detailProduct.type === "đl5l" && (
            <PerforatedPaper settings={settings} />
          )}
          {detailProduct.type === "photo" && <PhotoPaper settings={settings} />}
        </div>
        <ContactWays />
        <HomeFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    detailProduct: state.detailProduct,
    amount: state.amount,
    form: state.form,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (cartItem) => dispatch(actions.addToCart(cartItem)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailProduct)
);
