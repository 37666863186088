import React, { useEffect } from "react";
import "./SearchResult.scss";
import { useHistory } from "react-router-dom";

export const SearchResult = ({ result, setResult }) => {
  const history = useHistory();

  const checkType = (value) => {
    const typeCheck = [
      "cuộn in",
      "tem",
      "nhãn",
      "phiếu xuất kho",
      "phiếu nhập kho",
      "bill chuyển phát nhanh",
      "bill sea",
      "bill air",
      "hóa đơn atm",
      "hóa đơn edc",
      "phiếu lương",
      "khác",
      "phiếu thu chi",
      "đl5l",
    ];
    for (let i = 0; i < typeCheck.length; i++) {
      if (value === typeCheck[i]) {
        return true;
      }
    }
    return false;
  };

  const handleClick = async () => {
    // Check if the result object exists and has a name property
    if (result && result.id && result.name && result.type) {
      let check = checkType(result.type);
      let name = result.name;
    let decodedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
      .replace(/[èéẹẻẽêềếệểễ]/g, "e")
      .replace(/[ìíịỉĩ]/g, "i")
      .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
      .replace(/[ùúụủũưừứựửữ]/g, "u")
      .replace(/[ỳýỵỷỹ]/g, "y")
      .replace(/đ/g, "d")
      .replace(/[^a-z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .trim(); // Trim leading and trailing spaces
      try {
        if (check) {
          history.push(`/chi-tiet-in-bieu-mau/${result.id}/${decodedName}`);
          setResult("");
        } else if (!check) {
          history.push(`/chi-tiet-san-pham/${result.id}/${decodedName}`);
          setResult("");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

//   const handleChooseItem = async (e) => {
//     if (result && result.id && result.name && result.type && e.key === "Enter"){
//         let check = checkType(result.type);
//         let name = result.name;
//         let decodedName = name
//           .toLowerCase() // Convert to lowercase
//           .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
//           .replace(/[èéẹẻẽêềếệểễ]/g, "e")
//           .replace(/[ìíịỉĩ]/g, "i")
//           .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
//           .replace(/[ùúụủũưừứựửữ]/g, "u")
//           .replace(/[ỳýỵỷỹ]/g, "y")
//           .replace(/đ/g, "d")
//           .replace(/[^a-z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
//           .replace(/\s+/g, "-") // Replace spaces with hyphens
//           .trim(); // Trim leading and trailing spaces
//           try {
//               if (check) {
//                   history.push(`/chi-tiet-in-bieu-mau/${result.id}/${decodedName}`);
//               } else if (!check) {
//                   history.push(`/chi-tiet-san-pham/${result.id}/${decodedName}`);
                  
//               }
//           } catch (error) {
//               console.error(error);
              
//           }
//     }
//   };

//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       handleChooseItem(e);
//     };

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, []);

  return (
    <div
      className="search-result"
      onClick={handleClick}
    //   onKeyDown={(e) => handleChooseItem(e)}
    >
      {result && result.name}
    </div>
  );
};
