import React, { Component, useState } from "react";
import "./SearchBar.scss";
import { connect, useDispatch } from "react-redux";
import * as actions from "../../../store/actions";
import axios from "../../../axios";

export const SearchBar = ({ setResults }) => {
  const [input, setInput] = useState("");
  const dispatch = useDispatch();

  const fetchData = async (value) => {
    // // Dispatch the searchProductStart action using the input value
    // dispatch(actions.searchProductStart(value));

    try {
      // Call your API or data fetching logic here, replacing this placeholder
      const response = await axios.get(
        `/api/search-product?inputName=${value}`
      );
      setResults(response);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally handle errors for the user (e.g., display an error message)
    }
  };

  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  return (
    <div className="input-wrapper">
      <i className="fas fa-search"></i>
      <input
        type="text"
        placeholder="Tìm sản phẩm..."
        value={input}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchedProduct: state.product.productList,
});

const mapDispatchToProps = (dispatch) => ({
  productSearch: (inputName) => dispatch(actions.searchProductStart(inputName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
