import React, { Component, useState } from "react";
import { connect } from "react-redux";
import HomeHeader from "../../../HomePage/HomeHeader";
import Tab from "../../../HomePage/Section/Tab";
import ContactWays from "../../../HomePage/Section/ContactWays";
import HomeFooter from "../../../HomePage/HomeFooter";
import LastFooter from "../../../HomePage/LastFooter";
import "../ProductPage.scss";
import * as actions from "../../../../store/actions";
import { withRouter } from "react-router";
import CurrencyFormat from "react-currency-format";
import { toInteger } from "lodash";
import { outOfProductContact } from "../../../../services/userService";
import { toast } from "react-toastify";

class PerforatedPaperPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrProduct: {
        arrPerforatedPapers: [],
        phone: "",
      },

      amount: toInteger(1),
      form: "không chia",
      viewSize: window.innerWidth,
    };
  }

  componentDidMount() {
    this.props.loadPerforatedPaper();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.perforatedPapersRedux !== this.props.perforatedPapersRedux) {
      this.setState({
        arrPerforatedPapers: this.props.perforatedPapersRedux,
      });
    }
  }

  handleViewDetailProduct = (product) => {
    const name = product.name;
    const decodedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
      .replace(/[èéẹẻẽêềếệểễ]/g, "e")
      .replace(/[ìíịỉĩ]/g, "i")
      .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
      .replace(/[ùúụủũưừứựửữ]/g, "u")
      .replace(/[ỳýỵỷỹ]/g, "y")
      .replace(/đ/g, "d")
      .replace(/[^a-z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .trim(); // Trim leading and trailing spaces
    if (this.props.history) {
      this.props.history.push(`/chi-tiet-san-pham/${product.id}/${decodedName}`);
    }
  };

  handleViewDetailPrinted = (product) => {
    if (this.props.history) {
      this.props.history.push(`/chi-tiet-in-bieu-mau/${product.id}`);
    }
  };

  moveToRollPaperPage = () => {
    if (this.props.history) {
      this.props.history.push(`/san-pham/giay-cuon`);
    }
  };

  moveToPerforatedPaperPage = () => {
    if (this.props.history) {
      this.props.history.push(`/san-pham/giay-dllt`);
    }
  };

  moveToPhotoPaperPage = () => {
    if (this.props.history) {
      this.props.history.push(`/san-pham/giay-photo`);
    }
  };

  moveToPrintedPaperPage = () => {
    if (this.props.history) {
      this.props.history.push(`/giay-in`);
    }
  };

  addToCart = (item) => {
    let { amount, form } = this.state;
    const cartItem = {
      item,
      amount,
      form,
    };

    this.props.addToCart(cartItem);
  };

  buyNow = (item) => {
    let { amount, form } = this.state;
    const cartItem = {
      item,
      amount,
      form,
    };

    this.props.addToCart(cartItem);
    this.props.history.push(`/gio-hang`);
  };

  handleSendPhoneEmail = async (item, index) => {
    let res = await outOfProductContact({
      phone: this.state.arrPerforatedPapers[index].phone,
      type: item.type,
      productName: item.name,
    });

    if (res && res.errCode === 0) {
      if (this.state.arrPerforatedPapers[index].phone.length > 10) {
        toast.error(
          `Số điện thoại của quý khách không phù hợp. Xin hãy nhập lại`
        );
      } else {
        toast.success("Yêu cầu đã gửi");
        this.setState({
          phone: "",
        });
      }
    } else {
      toast.error(`Vui lòng nhập số điện thoại để chúng tôi gọi lại quý khách`);
    }
  };

  onChangeInput = (event, productIndex) => {
    let copyState = { ...this.state };
    copyState.arrPerforatedPapers[productIndex].phone = event.target.value;
    this.setState({
      ...copyState,
    });
  };

  render() {
    let arrPerforatedPapers = this.state.arrPerforatedPapers;
    let { phone } = this.state;
    return (
      <>
        <HomeHeader />
        <Tab />
        <div className="product-page">
          <div className="product-content">
            <div className="product-header">
              <span className="title-section">
                Giấy carbonless liên tục đục lỗ
              </span>
            </div>

            <div className="product-type-above">
              {/* <div className="type-change" onClick={() => this.toProductPage()}>
                Tất cả sản phẩm
              </div> */}
              <div
                className="type-change"
                onClick={() => this.moveToRollPaperPage()}
              >
                Giấy cuộn
              </div>
              <div
                className="type-change-active"
                onClick={() => this.moveToPerforatedPaperPage()}
              >
                Giấy carbonless liên tục đục lỗ
              </div>
              <div
                className="type-change"
                onClick={() => this.moveToPhotoPaperPage()}
              >
                Giấy photocopy
              </div>
              {/* <div className="type-change" onClick={() => this.moveToPrintedPaperPage()}>Giấy in</div> */}
            </div>

            <div className="product-body">
              {arrPerforatedPapers &&
                arrPerforatedPapers.length > 0 &&
                arrPerforatedPapers.map((item, index) => {
                  let imageBase64 = "";
                  if (item.image) {
                    imageBase64 = new Buffer.from(
                      item.image,
                      "base64"
                    ).toString("binary");
                  }
                  // let imageBase64 = item.image;
                  let name = `${item.name}`;
                  let price = `${item.price}`;
                  let calType = `${item.describe}`;
                  let type = `${item.type}`;
                  // let discount = `${item.discount}`
                  let stockAmount = `${item.Stock.quantity}`;
                  let unit = `${item.Stock.unit}`;
                  return (
                    <div className="slide-product" key={index}>
                      <div className="prod-img-container embed-responsive embed-responsive-1by1">
                        <img
                          src={imageBase64}
                          alt="prod-img"
                          onClick={() => this.handleViewDetailProduct(item)}
                          className="embed-responsive-item img-fluid"
                        />
                      </div>
                      <div className="product-detail">
                        <div
                          className="product-name-perforated"
                          onClick={() => this.handleViewDetailProduct(item)}
                        >
                          {name}
                        </div>
                        {type !== "đl5l" && (
                          <div className="price-add-cart">
                            <div className="d-flex align-items-center product-price-container text-danger font-weight-bold justify-content-between">
                              <div className="price d-flex">
                                <CurrencyFormat
                                  value={price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                <div className="ml-1">{calType}</div>
                              </div>
                              {stockAmount === "0" && (
                                <div className="stock">Hết hàng</div>
                              )}
                              {stockAmount !== "0" && (
                                <div className="stock">
                                  Tồn kho: {stockAmount}&nbsp;{unit}
                                </div>
                              )}
                            </div>
                            {stockAmount !== "0" && (
                              <div className="d-flex justify-content-between align-items-center buttons">
                                <button
                                  className="add-cart-button"
                                  onClick={() => this.addToCart(item)}
                                >
                                  <i className="fas fa-shopping-cart"></i>
                                  <span className="ml-1 add-cart">
                                    Thêm vào giỏ hàng
                                  </span>
                                </button>

                                <button
                                  className="add-cart-button"
                                  onClick={() => this.buyNow(item)}
                                >
                                  <span className="ml-1">Mua ngay</span>
                                </button>
                              </div>
                            )}

                            {stockAmount === "0" && (
                              <div className="request-contact">
                                <div className="request-form">
                                  <input
                                    className="col-12"
                                    type="tel"
                                    placeholder="Quý khách muốn mua hàng xin nhập SĐT"
                                    pattern="[0-9]{10}"
                                    required
                                    value={phone}
                                    onChange={(event) => {
                                      this.onChangeInput(event, index);
                                    }}
                                  ></input>
                                </div>

                                <button
                                  className="request-button col-12"
                                  onClick={() => {
                                    this.handleSendPhoneEmail(item, index);
                                  }}
                                >
                                  Gửi yêu cầu mua hàng
                                </button>
                              </div>
                            )}
                          </div>
                        )}

                        {type === "đl5l" && (
                          <div className="product-price text-danger text-uppercase font-weight-bold">
                            Liên hệ đặt hàng
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <ContactWays />
        <HomeFooter />
        <LastFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    perforatedPapersRedux: state.product.perforatedPapers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPerforatedPaper: () => dispatch(actions.fetchPerforatedPaper()),
    addToCart: (cartItem) => dispatch(actions.addToCart(cartItem)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PerforatedPaperPage)
);
