import axios from "../axios";

const saveStockProductService = (data) => {
  return axios.post("/api/save-info-stock-product", data);
};

const getAllStocks = (inputId) => {
  // template string
  return axios.get(`/api/get-all-stocks?id=${inputId}`);
};

const deleteStockProductFunc = (inputId) => {
  return axios.delete("/api/delete-stock-product", {
    data: {
      id: inputId,
    },
  });
};

const editStockProductFunc = (inputData) => {
  return axios.put("/api/edit-stock-product", inputData);
};

export {
  saveStockProductService,
  getAllStocks,
  deleteStockProductFunc,
  editStockProductFunc,
};
