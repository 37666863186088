import React, { Component } from "react";
import { connect } from "react-redux";
import "./ManageStockProduct.scss";
import * as actions from "../../../../store/actions";
// import style manually
import "react-markdown-editor-lite/lib/index.css";
import Select from "react-select";
import { getDetailInforProduct } from "../../../../services/productService";
import { CRUD_ACTIONS } from "../../../../utils";
import TableManageStock from "./TableManageStocks";

class ManageStockProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockId: "",
      productName: "",
      productId: "",
      productCode: "",
      quantity: "",
      unit: "",
      selectedOption: "",
      listProduct: [],
      hasOldData: false,
      action: "",
    };
  }

  componentDidMount() {
    this.props.fetchAllDetailProduct();
  }

  buildDataInputSelect = (inputData) => {
    let result = [];
    if (inputData && inputData.length > 0) {
      inputData.map((item, index) => {
        let object = {};
        let label = `${item.name}`;
        object.label = label;
        object.productId = item.id;
        object.stockId = item.stockId;
        result.push(object);
      });
    }

    return result;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allDetailProduct !== this.props.allDetailProduct) {
      let dataSelect = this.buildDataInputSelect(this.props.allDetailProduct);
      this.setState({
        listProduct: dataSelect,
      });
    }
  }

  handleSaveContentStock = () => {
    let { hasOldData } = this.state;
    this.props.saveStockProduct({
      productCode: this.state.productCode,
      quantity: this.state.quantity,
      unit: this.state.unit,
      productId: this.state.selectedOption.productId,
      productName: this.state.selectedOption.label,
      stockId: this.state.selectedOption.stockId,
      action: hasOldData === true ? CRUD_ACTIONS.EDIT : CRUD_ACTIONS.CREATE,
    });

    this.setState({
      selectedOption: "",
      productCode: "",
      quantity: "",
      unit: "",
      hasOldData: false,
    });
  };

  handleChangeSelect = async (selectedOption) => {
    this.setState({ selectedOption });
    let res = await getDetailInforProduct(selectedOption.productId);
    if (res && res.errCode === 0 && res.data.Stock) {
      let stock = res.data.Stock;
      this.setState({
        productId: stock.productId,
        productName: stock.productName,
        stockId: stock.stockId,
        productCode: stock.productCode,
        quantity: stock.quantity,
        unit: stock.unit,
        hasOldData: true,
      });
    } else {
      this.setState({
        productCode: "",
        quantity: "",
        unit: "",
        hasOldData: false,
      });
    }
  };

  onChangeInput = (e, id) => {
    let copyState = { ...this.state };
    copyState[id] = e.target.value;
    this.setState({
      ...copyState,
    });
  };

  handleEditStockProductFromParent = (stockProduct) => {
    this.setState({
      selectedOption: {
        stockId: stockProduct.stockId,
        productId: stockProduct.productId,
        label: stockProduct.productName,
        id: stockProduct.id,
      },

      productCode: stockProduct.productCode,
      quantity: stockProduct.quantity,
      unit: stockProduct.unit,
      hasOldData: true,
    });
  };

  render() {
    let { hasOldData } = this.state;
    return (
      <div className="manage-product-container">
        <div className="title">Thêm thông tin kho sản phẩm</div>
        <div className="more-info">
          <div className="content-left form-group col-4">
            <label>Chọn sản phẩm</label>
            <Select
              value={this.state.selectedOption}
              onChange={this.handleChangeSelect}
              options={this.state.listProduct}
            />
          </div>
        </div>
        <div className="manage-product-edit d-flex">
          <div className="left-content col-2">
            <div className="col">
              <label>ID sản phẩm: </label>
              <input
                className="form-control"
                type="text"
                value={
                  this.state.selectedOption
                    ? this.state.selectedOption.productId
                    : ""
                }
                disabled
              />
            </div>
            <div className="col">
              <label>Tên sản phẩm: </label>
              <input
                className="form-control"
                type="text"
                value={
                  this.state.selectedOption
                    ? this.state.selectedOption.label
                    : ""
                }
                disabled
              />
            </div>
            <div className="col">
              <label>Kho chứa: </label>
              <input
                className="form-control"
                type="text"
                value={
                  this.state.selectedOption
                    ? this.state.selectedOption.stockId
                    : ""
                }
                disabled
              />
            </div>
            <div className="col">
              <label>Mã sản phẩm: </label>
              <input
                className="form-control"
                type="text"
                value={this.state.productCode}
                onChange={(e) => this.onChangeInput(e, "productCode")}
              />
            </div>

            <div className="col">
              <label>Tồn kho: </label>
              <input
                className="form-control"
                type="text"
                value={this.state.quantity}
                onChange={(e) => this.onChangeInput(e, "quantity")}
              />
            </div>

            <div className="col">
              <label>Quy cách:</label>
              <input
                className="form-control"
                type="text"
                value={this.state.unit}
                onChange={(e) => this.onChangeInput(e, "unit")}
              />
            </div>

            <button
              onClick={() => this.handleSaveContentStock()}
              className={
                hasOldData === true
                  ? "save-stock-product"
                  : "create-stock-product"
              }
            >
              {hasOldData === true ? (
                <span>Lưu thông tin kho</span>
              ) : (
                <span>Tạo thông tin kho</span>
              )}
            </button>
          </div>
          <div className="right-content col">
            <TableManageStock
              handleEditStockProductFromParent={
                this.handleEditStockProductFromParent
              }
              hasOldData={this.state.hasOldData}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allDetailProduct: state.product.allDetailProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllDetailProduct: (id) => dispatch(actions.fetchAllDetailProduct()),
    saveStockProduct: (data) => dispatch(actions.saveStockProduct(data)),
    editStockProductRedux: (data) => dispatch(actions.editStockProduct(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageStockProduct);
