import React, { Component } from "react";
import { connect } from "react-redux";
import "./HomePage.scss";
import * as actions from "../../store/actions";
import { withRouter } from "react-router-dom";
import { getDetailInforPolicy } from "../../services/policyService";
import fbCover from "../../assets/fbCover.jpg";

class HomeFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrAllPolicy: [],
      isActive: false,
      width: "",
      height: "",
    };
  }

  componentDidMount() {
    this.props.loadAllPolicyPage();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allPolicyPageRedux !== this.props.allPolicyPageRedux) {
      this.setState({
        arrAllPolicy: this.props.allPolicyPageRedux,
      });
    }

    if (this.props.match && this.props.match.params) {
      const { id: prevId } = prevProps.match.params;
      const { id } = this.props.match.params;

      // Check if the product ID or name has changed
      if (id !== prevId) {
        this.fetchProductDetails(id);
      }
    }
  }

  async fetchProductDetails(id) {
    let res = await getDetailInforPolicy(id);
    if (res && res.errCode === 0) {
      this.setState({
        detailPolicy: res.data,
      });
    }
  }

  handleViewDetailPolicy = (policy) => {
    const name = policy.name;
    const decodedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
      .replace(/[èéẹẻẽêềếệểễ]/g, "e")
      .replace(/[ìíịỉĩ]/g, "i")
      .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
      .replace(/[ùúụủũưừứựửữ]/g, "u")
      .replace(/[ỳýỵỷỹ]/g, "y")
      .replace(/đ/g, "d")
      .replace(/[^a-z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .trim(); // Trim leading and trailing spaces
    if (this.props.history) {
      this.props.history.push(`/chi-tiet-chinh-sach/${policy.id}/${decodedName}`);
    }
  };

  handleSetWidth = () => {
    if (320 <= window.innerWidth <= 576) {
      this.setState({
        width: 150,
      });
    } else if (577 <= window.innerWidth <= 767) {
      this.setState({
        width: 200,
      });
    } else if (768 <= window.innerWidth <= 991) {
      this.setState({
        width: 250,
      });
    } else if (992 <= window.innerWidth <= 1199) {
      this.setState({
        width: 300,
      });
    } else if (1200 <= window.innerWidth) {
      this.setState({
        width: 400,
      });
    }
  };

  render() {
    let arrAllPolicy = this.state.arrAllPolicy;
    let deviceWidth = this.state.deviceWidth;
    return (
      <div className="container-fluid home-footer-container">
        <div className="row py-1">
          <div className="intro-container">
            <div className="container-fluid">
              <h4 className="font-weight-bold text-uppercase">
                Giới thiệu công ty
              </h4>
              <div className="col d-flex flex-column justify-content-between">
                <span>
                Công ty TNHH Giấy Vi Tính Liên Sơn hoạt động kinh doanh trong nhiều lĩnh vực, bao gồm:
                </span>
                <span>&#x261B;In ấn biểu mẫu, chứng từ ngân hàng - kế toán, vé xe, bill chuyển phát nhanh, tem - nhãn - decal, bao bì các loại ....</span>
                <span>
                  &#x261B;Sản xuất - cung cấp các loại giấy in vi tính đục lỗ
                  liên tục, giấy Photocopy, giấy Carbonless tự nhân bản, giấy in
                  decal, giấy in nhiệt Thermal, giấy ATM, giấy cho máy thanh
                  toán thẻ ghi nợ Visa, Master Card, giấy dùng cho hệ thống máy
                  lấy số thứ tự xếp hàng tự động.
                </span>
                <span>
                  &#x261B;Phân phối các loại giấy Photocopy A0, A1, A3, A4 từ
                  các thương hiệu IK Natural, Double A, Number 1, Multipurpose,
                  Excel Pro, …và giấy cuộn A0, A1 chất lượng cao để sử dụng in
                  cho các bản vẽ thiết kế và đồ họa.
                </span>
                <span>
                  &#x261B;Cung cấp giải pháp hoá đơn điện tử và chữ ký số.
                </span>
              </div>
            </div>
          </div>
          <div className="branch-container">
            <div className="container-fluid">
              <h4 className="font-weight-bold text-uppercase">
                Công ty TNHH Giấy vi tính Liên Sơn
              </h4>
              <div className="d-flex">
                <div className="col-3 font-weight-bold">Trụ sở:</div>
                <div className="col d-flex flex-column">
                  <span>34 Nguyễn Bỉnh Khiêm, Quận 1, TP.HCM</span>
                  <span>Tel: (028)39.100.555</span>
                  <span>Website: https://lienson.vn - Email: info@lienson.vn</span>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 font-weight-bold">Hà Nội:</div>
                <div className="col d-flex flex-column">
                  <span>
                    780 Minh Khai, P. Vĩnh Tuy, Q. Hai Bà Trưng, TP. Hà Nội
                  </span>
                  <span>Tel: (024)3636.4646</span>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 font-weight-bold">Đà Nẵng:</div>
                <div className="col d-flex flex-column">
                  <span>29 Phạm Nhữ Tăng, Quận Thanh Khê, TP. Đà Nẵng</span>
                  <span>Tel: 0905.867.889 - 0913.884.207</span>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 font-weight-bold">Tây Ninh:</div>
                <div className="col d-flex flex-column">
                  <span>302 Đường Tua Hai, KP2, Phường 1, TP. Tây Ninh</span>
                  <span>Tel: (0276)3.818.181 - 0913.884.207</span>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 font-weight-bold">Đồng Nai:</div>
                <div className="col d-flex flex-column">
                  <span>139 CMT8, P. Hòa Bình, TP. Biên Hòa</span>
                  <span>Tel: (0251)384.2383</span>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 font-weight-bold">Bình Dương:</div>
                <div className="col d-flex flex-column">
                  <span>
                    35 Thích Quảng Đức, KP.2, P. Phú Hòa, TP. Thủ Dầu Một
                  </span>
                  <span>Tel: (0274)3844.118</span>
                </div>
              </div>
            </div>
          </div>
          <div className="policy-container">
            <div className="container-fluid">
              <h4 className="font-weight-bold text-uppercase">Chính sách</h4>
              {arrAllPolicy &&
                arrAllPolicy.length > 0 &&
                arrAllPolicy.map((item, index) => {
                  let name = `${item.name}`;
                  return (
                    <div
                      className="col d-flex flex-column justify-content-between policy-nav"
                      key={index}
                    >
                      <div
                        className={item.active ? "policy-active" : "policy"}
                        onClick={() => this.handleViewDetailPolicy(item, index)}
                      >
                        <div className="policy-name">{name}</div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <a href="https://www.facebook.com/congtygiayvitinhlienson" target="_blank">
              <img
                src={fbCover}
                alt="Facebook Page"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allPolicyPageRedux: state.policy.allPoliciesPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllPolicyPage: () => dispatch(actions.fetchAllPolicyPage()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeFooter)
);
