import React, { Component } from "react";
import { connect } from "react-redux";
import "../HomePage.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as actions from "../../../store/actions";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      arrPopup: [],
    };
  }
  componentDidMount() {
    this.props.loadAllPopupPage();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allPopupPageRedux !== this.props.allPopupPageRedux) {
      this.setState({
        arrPopup: this.props.allPopupPageRedux,
      });
    }
  }

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleEsc = (e) => {
    if (e.key === "esc") {
      this.setState({
        show: false,
      });
    }
  };
  
  handleViewDetailProduct = () => {
    if (this.props.history) {
      this.props.history.push(`chi-tiet-san-pham/49/giay-accura-70gsm-a4`);
    }
  };

  render() {
    let settings = {
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      vertical: false,
    };
    let show = this.state.show;
    let arrPopup = this.state.arrPopup;

    return (
      <div className="container-fluid">
        <Modal show={show} onHide={() => this.handleClose()}>
          <Modal.Body>
            <div>
              <div
                style={{ cursor: "pointer" }}
                variant="dark"
                onClick={() => this.handleClose()}
                onKeyDown={(e) => this.handleEsc(e)}
              >
                X
              </div>
              <div>
                <Slider {...settings}>
                  {arrPopup &&
                    arrPopup.length > 0 &&
                    arrPopup.map((item, index) => {
                      let imageBase64 = "";
                      if (item.image) {
                        imageBase64 = new Buffer.from(
                          item.image,
                          "base64"
                        ).toString("binary");
                      }
                      return (
                        <div className="popup" key={index}>
                          <img
                            loading="lazy"
                            src={imageBase64}
                            alt="prod-img"
                            style={{ height: "100%", width: "100%", cursor: "pointer", }}
                            onClick={() => this.handleViewDetailProduct()}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    lang: state.app.language,

    allPopupPageRedux: state.popup.allPopupPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllPopupPage: () => dispatch(actions.fetchAllPopupPage()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Popup));
