import React, { Component } from "react";
import { connect } from "react-redux";
import HomeHeader from "../../../HomePage/HomeHeader";
import HomeFooter from "../../../HomePage/HomeFooter";
import "../ProductPage.scss";
import * as actions from "../../../../store/actions";
import { withRouter } from "react-router";
import Tab from "../../../HomePage/Section/Tab";
import ContactOrders from "../../../HomePage/Section/ContactOrders";

class OtherPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrOtherPrints: [],
    };
  }

  componentDidMount() {
    this.props.loadOtherPrint();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.OtherPrintsRedux !== this.props.OtherPrintsRedux) {
      this.setState({
        arrOtherPrints: this.props.OtherPrintsRedux,
      });
    }
  }

  handleViewDetailProduct = (product) => {
    const name = product.name;
    const decodedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
      .replace(/[èéẹẻẽêềếệểễ]/g, "e")
      .replace(/[ìíịỉĩ]/g, "i")
      .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
      .replace(/[ùúụủũưừứựửữ]/g, "u")
      .replace(/[ỳýỵỷỹ]/g, "y")
      .replace(/đ/g, "d")
      .replace(/[^a-z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .trim(); // Trim leading and trailing spaces
    if (this.props.history) {
      this.props.history.push(`/chi-tiet-in-bieu-mau/${product.id}/${decodedName}`);
    }
  };

  toPrintedRollPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/cuon-in`);
    }
  };

  toLabelStampPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/nhan-tem`);
    }
  };

  toStampPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/tem`);
    }
  };

  toDocumentPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/chung-tu`);
    }
  };

  toExpressPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/bill-chuyen-phat-nhanh`);
    }
  };
  toImportExportPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/phieu-xuat-nhap-kho`);
    }
  };

  toPaycheckPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/phieu-luong`);
    }
  };

  toSeaAirBillPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/bill-sea-air`);
    }
  };

  toAtmBillPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/bill-atm`);
    }
  };

  toEdcBillPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/bill-edc`);
    }
  };

  toOtherPrintPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/mau-in-khac`);
    }
  };

  toPackagePage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/bao-bi`);
    }
  };

  toEnvelopePage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/bao-thu`);
    }
  };

  toPrintedFormPage = () => {
    if (this.props.history) {
      this.props.history.push(`/in-bieu-mau/mau-in`);
    }
  };

  render() {
    let arrOtherPrints = this.state.arrOtherPrints;
    return (
      <>
        <HomeHeader />
        <Tab />
        <div className="product-page">
          <div className="product-content">
            <div className="product-header">
              <span className="title-section">Mẫu in khác</span>
            </div>

            <div className="product-type-above">
              <div
                className="type-change"
                onClick={() => this.toPrintedRollPage()}
              >
                Giấy cuộn in nội dung
              </div>
              <div
                className="type-change"
                onClick={() => this.toLabelStampPage()}
              >
                Nhãn / tem
              </div>
              <div
                className="type-change"
                onClick={() => this.toDocumentPage()}
              >
                Phiếu thu / chi
              </div>
              <div
                className="type-change"
                onClick={() => this.toImportExportPage()}
              >
                Phiếu xuất / nhập kho
              </div>
              <div
                className="type-change"
                onClick={() => this.toPaycheckPage()}
              >
                Phiếu lương
              </div>
            </div>
            <div className="product-type-below">
              <div className="type-change" onClick={() => this.toExpressPage()}>
                Bill chuyển phát nhanh
              </div>
              <div
                className="type-change"
                onClick={() => this.toSeaAirBillPage()}
              >
                Bill SEA / AIR
              </div>
              <div className="type-change" onClick={() => this.toAtmBillPage()}>
                Hóa đơn ATM
              </div>
              <div className="type-change" onClick={() => this.toEdcBillPage()}>
                Hóa đơn EDC / cà thẻ
              </div>
              <div
                className="type-change-active"
                onClick={() => this.toOtherPrintPage()}
              >
                Mẫu in khác
              </div>
            </div>

            <div className="product-body">
              {arrOtherPrints &&
                arrOtherPrints.length > 0 &&
                arrOtherPrints.map((item, index) => {
                  let imageBase64 = "";
                  if (item.image) {
                    imageBase64 = new Buffer.from(
                      item.image,
                      "base64"
                    ).toString("binary");
                  }
                  let name = `${item.name}`;
                  // let discount = `${item.discount}`
                  return (
                    <div
                      className="slide-product"
                      key={index}
                      onClick={() => this.handleViewDetailProduct(item)}
                    >
                      <div className="prod-img-container embed-responsive embed-responsive-1by1">
                        <img src={imageBase64} alt="prod-img" className="embed-responsive-item img-fluid"/>
                      </div>
                      <div className="product-detail">
                        <div className="product-name">{name}</div>
                        <div className="product-price text-danger">
                          Liên hệ đặt hàng
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <ContactOrders />
        <HomeFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    OtherPrintsRedux: state.product.otherPrints,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadOtherPrint: () => dispatch(actions.fetchOtherPrint()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtherPrint)
);
