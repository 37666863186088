import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import HomeHeader from "../../HomePage/HomeHeader";
import Tab from "../../HomePage/Section/Tab";
import HomeFooter from "../../HomePage/HomeFooter";
import DetailOrder from "../../Customer/Order/DetailOrder";
import "./DetailCart.scss";
import CurrencyFormat from "react-currency-format";
import { toInteger } from "lodash";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { withRouter, useHistory } from "react-router-dom";
import * as actions from "../../../store/actions";
import { toast } from "react-toastify";
import { addToOrder } from "../../../store/actions";
import ContactWays from "../../HomePage/Section/ContactWays";
import Slider from "react-slick";

const DetailCart = ({
  cartItems,
  SaveToOrder,
  allPopupPageRedux,
  loadAllPopupPage,
}) => {
  useEffect(() => {
    loadAllPopupPage();
  }, []);
  const [orderNumber, setOrderNumber] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [customerPhone, setCustomerPhone] = useState(null);
  const [receiveAddress, setReceiveAdress] = useState(null);
  const [customerCo, setCustomerCo] = useState(null);
  const [customerTax, setCustomerTax] = useState(null);
  const [customerCoAddress, setCustomerCoAddress] = useState(null);

  const [showDetailOrder, setShowDetailOrder] = useState(false);

  const [couponConfirmed, setCouponConfirmed] = useState(false);

  const [show, setShow] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  const [item, setItem] = useState();
  const [selectedOption, setSelectedOption] = useState("Không");
  const [selectedMethod, setSelectedMethod] = useState("Tiền mặt");

  const generatedOrderNumber = randomNumber();

  const dispatch = useDispatch();

  const orderItem = cartItems.map((item, index) => {
    return {
      name: item.detailProduct ? item.detailProduct.name : item.item.name,
      amount: item.amount,
      price: item.detailProduct ? item.detailProduct.price : item.item.price,
      form: item.form,
      total:
        item.amount *
        (item.detailProduct ? item.detailProduct.price : item.item.price),
      type: item.detailProduct ? item.detailProduct.type : item.item.type,
    };
  });
  const totalAmount = cartItems
    .map((item) => {
      return item.amount ? item.amount : 0;
    })
    .reduce((a, b) => a + b, 0);
  const preTotal = cartItems
    .map((item) => {
      return (
        (item.amount ? item.amount : 0) *
        (item.detailProduct ? item.detailProduct.price : item.item.price)
      );
    })
    .reduce((a, b) => a + b, 0);
  const vat = (preTotal * 8) / 100;
  const discount = calcDiscount(preTotal);
//   const shipFee = calcShipFee(preTotal);
  const shipFee = 35000;
  const couponDiscount = calcCouponDiscount(preTotal);
  const finalTotal = couponConfirmed
    ? preTotal + vat + shipFee - discount - couponDiscount
    : preTotal + vat + shipFee - discount;
  const history = useHistory();
  
  const totalWages = preTotal + vat - discount;

  const coupon = "freeship";

  // const stockAmountLeft = cartItems.map((item) => {
  //   return toInteger(
  //     (item.detailProduct
  //       ? item.detailProduct.Stock.quantity
  //       : item.item.Stock.quantity) - item.amount
  //   );
  // });

  function randomNumber() {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 5) {
      result += characters.charAt(Math.random() * charactersLength);
      counter += 1;
    }
    return result;
  }

  function checkOrderNumber() {
    if (orderNumber === null) {
      setOrderNumber(generatedOrderNumber);
      return generatedOrderNumber;
    } else {
      return orderNumber;
    }
  }

  function handleRemove(index) {
    // Check if the index is already deleted.
    if (index > cartItems.length) {
      // If the index is already deleted, set it to the previous index.
      index -= 1;
    }
    cartItems.splice(index, 1);
    setItem([...cartItems]);
    localStorage.setItem("cartNumber", cartItems.length);
  }

  function incNum(item) {
    item.amount += 1;
    setItem(item.amount);
  }

  function decNum(item) {
    if (item.amount > 1) {
      item.amount -= 1;
      setItem(item.amount);
    }
  }

  function handleChange(index, e) {
    const updatedCartItems = [...cartItems];
    if (e.target.value >= 1) {
      updatedCartItems[index].amount = parseInt(e.target.value);
    } else {
      updatedCartItems[index].amount = "";
    }
    setItem(updatedCartItems);
  }

  function checkValidate() {
    const itemAmount = orderItem.map((item, index) => {
      return { index, amount: item.amount };
    });

    let hasValidAmounts = true;
    itemAmount.forEach((item) => {
      if (!item.amount) {
        toast.error("Số lượng sản phẩm không được để trống");
        hasValidAmounts = false; // Mark as invalid
      }
    });

    if (selectedOption === "Có") {
      if (!customerCo) {
        toast.error("Tên công ty không được để trống");
      } else if (!customerTax) {
        toast.error("Mã số thuế không được để trống");
      } else if (!/^[0-9]{1,13}$/.test(customerTax)) {
        toast.error("Mã số thuế không phù hợp. Xin hãy nhập lại");
      } else if (customerTax.length > 13) {
        toast.error("Mã số thuế không được quá 13 số");
      } else if (!customerCoAddress) {
        toast.error("Địa chỉ công ty không được để trống");
      } else if (!customerEmail) {
        toast.error("E-mail không được để trống");
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerEmail)) {
        toast.error("E-mail của quý khách không phù hợp. Xin hãy nhập lại");
      } else if (!hasValidAmounts) {
        setShow(false);
      } else {
        setShow(true);
      }
    } else {
        if (!customerEmail) {
        toast.error("E-mail không được để trống");
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerEmail)) {
        toast.error("E-mail của quý khách không phù hợp. Xin hãy nhập lại");
      } else if (!customerName) {
        toast.error("Tên người mua không được để trống");
      } else if (/^[0-9\s.,!?]*$/.test(customerName)) {
        toast.error("Tên không phù hợp. Xin hãy nhập lại");
      } else if (!customerPhone) {
        toast.error("Số điện thoại không được để trống");
      } else if (!/^[0-9]{1,12}$/.test(customerPhone)) {
        toast.error("Số điện thoại không phù hợp. Xin hãy nhập lại");
      } else if (customerPhone.length < 10) {
        toast.error("Số điện thoại không được ít hơn 10 số");
      } else if (customerPhone.length > 12) {
        toast.error("Số điện thoại không được quá 12 số");
      } else if (!receiveAddress) {
        toast.error("Địa chỉ giao hàng không được để trống");
      } else if (!hasValidAmounts) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  }

  function handleConfirmOrder() {
    if (!selectedMethod) {
      toast.error("Xin chọn phương thức thanh toán");
    } else if (!selectedOption) {
      toast.error("Xin chọn tùy chọn in hóa đơn");
    } else {
      checkValidate();
    }
  }

  function handleMoveToOrder() {
    const orderDetails = {
      orderItem,
      generatedOrderNumber,
      customerName,
      customerPhone,
      receiveAddress,
      customerTax,
      customerCo,
      customerCoAddress,
      customerEmail,
      selectedMethod,
      selectedOption,
      preTotal,
      vat,
      discount,
      finalTotal,
      totalAmount,
      shipFee,
      couponDiscount,
    };
    dispatch(addToOrder(orderDetails));
  }

  function handleSaveToOrder() {
    const description = [];
    const stockDescription = [];
    const orderNumber = checkOrderNumber();
    if (cartItems && cartItems.length > 0) {
      cartItems.map((item, index) => {
        let object = {};
        object.orderNumber = orderNumber;
        object.productName = item.detailProduct?.name || item.item.name;
        object.amount = item.amount;
        object.price = item.detailProduct
          ? item.detailProduct.price
          : item.item.price;
        object.type = item.detailProduct?.type || item.item.type;
        object.form = item.form;
        description.push(object);
      });
    }
    if (cartItems && cartItems.length > 0) {
      cartItems.map((item, index) => {
        let stockObject = {};
        stockObject.id = item.detailProduct
          ? item.detailProduct.Stock.id
          : item.item.Stock.id;
        stockObject.productId = item.detailProduct
          ? item.detailProduct.id
          : item.item.id;
        stockObject.quantity =
          (item.detailProduct
            ? item.detailProduct.Stock.quantity
            : item.item.Stock.quantity) - item.amount;
        stockDescription.push(stockObject);
      });
    }
    SaveToOrder({
      orderNumber: orderNumber,
      statusID: "S1",
      emailAddress: customerEmail,
      fullName: customerName,
      phoneNumber: customerPhone,
      taxNumber: customerTax,
      receiveAddress: receiveAddress,
      companyName: customerCo,
      companyAddress: customerCoAddress,
      description: description,
      stockDescription: stockDescription,
      preTotal: preTotal,
      vat: vat,
      shipFee: shipFee,
      totalPrice: finalTotal,
      discount: discount,
      payments: selectedMethod,
      billPrinted: selectedOption,
      couponDiscount: couponDiscount,
    });
    setItem(cartItems);
    // handleSendEmail();
    // handleCaptureOrder();
    handleMoveToOrder();
    cartItems.splice(0, cartItems.length);
    history.push("/hoa-don");
  }

  function calcDiscount(preTotal) {
    if (preTotal < 5000000) {
      return 0;
    }
    if (5000000 <= preTotal) {
      if (10000000 <= preTotal) {
        if (20000000 <= preTotal) {
          return preTotal * (5 / 100);
        }
        return preTotal * (4 / 100);
      }
      return preTotal * (2 / 100);
    }
  }

  function calcShipFee(preTotal) {
    if (preTotal < 10000000) {
      return 35000;
    } else {
      if (preTotal < 20000000) {
        return 20000;
      } else {
        return 0;
      }
    }
  }

  function calcCouponDiscount(preTotal) {
    if (preTotal < 1000000) {
      return 0;
    } else if (!couponConfirmed) {
      return 0;
    } else {
      return toInteger(shipFee);
    }
  }

  function handleChangeCustom(index, e) {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].form = e.target.value ? e.target.value : 0;
    setItem(updatedCartItems);
  }

  function handleCheckCoupon(event) {
    let check = event.target.value;
    if (event.key === "Enter") {
      if (check === coupon) {
        setCouponConfirmed(true);
      } else {
        toast.error("Coupon không tồn tại");
      }
    }
  }

  function toRollPaperPage() {
    history.push("/san-pham/giay-cuon");
  }

  function toPerforatedPage() {
    history.push("/san-pham/giay-dllt");
  }

  function toPhotoPage() {
    history.push("/san-pham/giay-photo");
  }

  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    vertical: false,
    adaptiveHeight: true,
  };

  return (
    <>
      <HomeHeader />
      <Tab />
      <div className="d-flex">
        {cartItems.length === 0 && (
          <div className="container-fluid">
            <div className="cart-title">Giỏ hàng</div>
            <div className="cart-empty-container">
              <div className="empty-cart">Không có sản phẩm trong giỏ hàng</div>
              <div className="return-pages">
                {/* <button onClick={toProductPage}>Xem tất cả sản phẩm</button> */}
                <div onClick={toRollPaperPage}>Xem giấy cuộn</div>
                <div onClick={toPerforatedPage}>Xem giấy đục lỗ liên tục</div>
                <div onClick={toPhotoPage}>Xem giấy photocopy</div>
              </div>
            </div>
          </div>
        )}

        {cartItems.length !== 0 && (
          <div className="container-fluid">
            <div className="cart-title">Giỏ hàng</div>
            <div
              className="cart-detail-container container-fluid d-flex flex-column my-2 border border-dark"
              style={{ maxWidth: "950px" }}
            >
              {selectedOption === "Có" && (
                <div className="export-info border-bottom border-dark">
                  <div className="row text-danger d-flex">
                    <div className="col-6">
                      <h5 className="font-weight-bold">
                        THÔNG TIN XUẤT HÓA ĐƠN
                      </h5>
                    </div>
                    <div className="col-6 small text-right">
                      <h6 className="font-weight-bold">
                        Xuất hóa đơn VAT: &nbsp;
                        <select
                          style={{ textAlign: "center" }}
                          defaultValue={"Có"}
                          onChange={(e) =>
                            setSelectedOption(e.currentTarget.value)
                          }
                        >
                          <option value="Có">Có</option>
                          <option value="Không">Không</option>
                        </select>
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h6>
                        Tên công ty:
                        <span>*</span>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => setCustomerCo(e.target.value)}
                        />
                      </h6>
                    </div>
                    <div className="col-4">
                      <h6>
                        Mã số thuế:
                        <span>*</span>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => setCustomerTax(e.target.value)}
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h6>
                        Địa chỉ công ty:
                        <span>*</span>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => setCustomerCoAddress(e.target.value)}
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex flex-column">
                <div className="cust-info border-bottom border-dark">
                  {selectedOption === "Không" && (
                    <div className="col-6 text-danger text-left">
                      <h6 className="font-weight-bold">
                        Xuất hóa đơn VAT: &nbsp;
                        <select
                          style={{ textAlign: "center" }}
                          defaultValue={"Không"}
                          onChange={(e) =>
                            setSelectedOption(e.currentTarget.value)
                          }
                        >
                          <option value="Có">Có</option>
                          <option value="Không">Không</option>
                        </select>
                      </h6>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-4">
                      <h6>
                        E-mail:
                        <span>*</span>
                        <input
                          className="form-control"
                          type="email"
                          onChange={(e) => setCustomerEmail(e.target.value)}
                        />
                      </h6>
                    </div>
                    <div className="col-4">
                      <h6>
                        Tên người mua:
                        <span>*</span>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                      </h6>
                    </div>
                    <div className="col-4">
                      <h6>
                        SĐT:
                        <span>*</span>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => setCustomerPhone(e.target.value)}
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h6>
                        Địa chỉ giao hàng:
                        <span>*</span>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => setReceiveAdress(e.target.value)}
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {selectedMethod === "Tiền mặt" && (
                        <div>
                          <h6>
                            Phương thức thanh toán: &nbsp;
                            <select
                              defaultValue="Tiền mặt"
                              onChange={(e) =>
                                setSelectedMethod(e.currentTarget.value)
                              }
                            >
                              Chọn phương thức trả tiền
                              <option value="Tiền mặt">Tiền mặt</option>
                              <option value="Chuyển khoản">Chuyển khoản</option>
                            </select>
                          </h6>
                          <div className="text-primary small">
                            (Quý khách thanh toán cho người vận chuyển sau khi
                            nhận và kiểm tra hàng)
                          </div>
                        </div>
                      )}

                      {selectedMethod === "Chuyển khoản" && (
                        <div>
                          <h6>
                            Phương thức thanh toán: &nbsp;
                            <select
                              defaultValue="Chuyển khoản"
                              onChange={(e) =>
                                setSelectedMethod(e.currentTarget.value)
                              }
                            >
                              Chọn phương thức trả tiền
                              <option value="Tiền mặt">Tiền mặt</option>
                              <option value="Chuyển khoản">Chuyển khoản</option>
                            </select>
                          </h6>
                          <div className="text-primary small">
                            (Quý khách thanh toán theo số tài khoản
                            037-1001-888888 NH Vietcombank - CN Tân Định)
                            <br />
                            (Cú pháp "Số điện thoại _ Mã hóa đơn")
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <table className="table-borderless table-sm">
                  <tbody className="border-bottom border-dark order-detail">
                    <tr className="text-center border-bottom border-dark">
                      <th className="col-1">STT</th>
                      <th className="col-6">Tên sản phẩm</th>
                      <th className="col-1">SL</th>
                      <th className="col-1">ĐVT</th>
                      <th className="col-1 text-right">Đơn giá</th>
                      <th className="col-2 text-right">Thành tiền</th>
                    </tr>

                    {cartItems.map((item, index) => {
                      const itemTotalPrice =
                        (item.amount ? item.amount : 0) *
                        (item.detailProduct
                          ? item.detailProduct.price
                          : item.item.price);

                      const calType = (
                        item.detailProduct
                          ? item.detailProduct.type === "cuộn"
                          : item.item.type === "cuộn"
                      )
                        ? "Cuộn"
                        : (
                            item.detailProduct
                              ? item.detailProduct.type === "đllt"
                              : item.item.type === "đllt"
                          )
                        ? "Thùng"
                        : "Ream";

                      return (
                        <tr className="text-center " key={index}>
                          <th scope="row" className="col-1">
                            {index + 1}
                          </th>
                          <td className="col-6 text-left name">
                            {item.detailProduct
                              ? item.detailProduct.name
                              : item.item.name}
                            &nbsp;
                            {item.detailProduct
                              ? item.detailProduct.type !== "cuộn" &&
                                item.detailProduct.type !== "photo" && (
                                  <select
                                    defaultValue={item.form}
                                    onChange={(e) =>
                                      handleChangeCustom(index, e)
                                    }
                                  >
                                    <option value="không chia">
                                      Không chia
                                    </option>
                                    <option value="chia đôi">Chia đôi</option>
                                  </select>
                                )
                              : item.item.type !== "cuộn" &&
                                item.item.type !== "photo" && (
                                  <select
                                    defaultValue={item.form}
                                    onChange={(e) =>
                                      handleChangeCustom(index, e)
                                    }
                                  >
                                    <option value="không chia">
                                      Không chia
                                    </option>
                                    <option value="chia đôi">Chia đôi</option>
                                  </select>
                                )}
                            &nbsp;
                            <button
                              className="delete-btn"
                              onClick={() => handleRemove(index)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>

                          <td className="col-1 amount">
                            <button
                              className="decrease"
                              type="button"
                              onClick={() => decNum(item)}
                              disabled={item.amount ? false : true}
                            >
                              -
                            </button>

                            <input
                              type="number"
                              className="amount-number"
                              placeholder="0"
                              value={item.amount}
                              onChange={(e) => handleChange(index, e)}
                            />

                            <button
                              className="increase"
                              type="button"
                              onClick={() => incNum(item)}
                              disabled={item.amount ? false : true}
                            >
                              +
                            </button>
                          </td>
                          <td className="col-1">{calType}</td>
                          <td className="text-right col-1">
                            <CurrencyFormat
                              value={
                                item.detailProduct
                                  ? item.detailProduct.price
                                  : item.item.price
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td className="text-right col-2">
                            <CurrencyFormat
                              value={itemTotalPrice}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>

                  <tbody className="border-bottom border-dark order-price">
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right" scope="col">
                        Tổng:
                      </td>
                      <td scope="col">{totalAmount}</td>
                      <td colSpan={2}></td>
                      <td className="text-right" scope="col">
                        <CurrencyFormat
                          value={preTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>
                    
                    {discount !== 0 && (
                      <tr>
                        <td></td>
                        <td className="text-right text-danger">Chiết khấu:</td>
                        <td colSpan={3}></td>
                        <td className="text-right text-danger">
                          <span>-</span>
                          <CurrencyFormat
                            value={discount}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                    )}
                    
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right">VAT(8%):</td>
                      <td colSpan={3}></td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={vat}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>
                    
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right">Tổng tiền hàng:</td>
                      <td colSpan={3}></td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={totalWages}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="text-right">Phí giao hàng:</td>
                      <td colSpan={3}></td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={shipFee}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right small text-danger" colSpan={6}>
                        (Ngoài bán kính giao hàng 5km, chúng tôi sẽ liên lạc báo phí giao hàng)
                      </td>
                    </tr>

                    {preTotal >= toInteger(1000000) && (
                      <tr>
                        <td></td>
                        <td className="text-right text-danger">Mã giảm giá:</td>
                        <td colSpan={3}></td>
                        <td className="text-right text-danger">
                          <div className="coupon">
                            {!couponConfirmed && (
                              <span className="coupon-input">
                                <input
                                  className="col-8"
                                  type="text"
                                  onKeyDown={(event) =>
                                    handleCheckCoupon(event)
                                  }
                                />
                              </span>
                            )}

                            {couponConfirmed && (
                              <span className="coupon-number">
                                <span>-</span>
                                <CurrencyFormat
                                  value={couponDiscount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td></td>
                      <td className="text-right text-uppercase">
                        <h5 className="font-weight-bold">
                          Tổng tiền thanh toán:
                        </h5>
                      </td>
                      <td colSpan={2}></td>
                      <td className="text-right" colSpan={2}>
                        <h5 className="font-weight-bold">
                          <CurrencyFormat
                            value={finalTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                            className="total-value"
                          />
                          &nbsp; VNĐ
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <button
                  className="container-fluid commit-btn"
                  onClick={() => handleConfirmOrder()}
                >
                  Đặt hàng
                </button>
              </div>
            </div>

            {show && (
              <div className="alert-overlay">
                <Alert
                  show={show}
                  variant="warning"
                  transition="true"
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: "1000",
                    textAlign: "center",
                    transition: "step-start",
                  }}
                >
                  <Alert.Heading style={{ textAlign: "center" }}>
                    Lưu ý
                  </Alert.Heading>
                  <p style={{ textAlign: "center" }}>
                    Xin quý khách vui lòng kiểm tra lại danh sách sản phẩm đã
                    đặt và thông tin đặt hàng trước khi xác nhận. Sau khi xác
                    nhận giỏ hàng sẽ tự động xóa.
                  </p>
                  <p style={{ textAlign: "center" }}>Xin cảm ơn quý khách</p>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <Button
                      onClick={() => handleSaveToOrder()}
                      variant="outline-success"
                    >
                      Xác nhận
                    </Button>

                    <Button
                      onClick={() => (
                        setShow(false), setShowDetailOrder(false)
                      )}
                      variant="outline-danger"
                    >
                      Trở lại
                    </Button>
                  </div>
                </Alert>
              </div>
            )}
          </div>
        )}

        {/* <div className="popup-container">
          <Slider {...settings}>
            {allPopupPageRedux &&
              allPopupPageRedux.length > 0 &&
              allPopupPageRedux.map((item, index) => {
                let imageBase64 = "";
                if (item.image) {
                  imageBase64 = new Buffer.from(item.image, "base64").toString(
                    "binary"
                  );
                }
                return (
                  <div className="popup" key={index}>
                    <img
                      loading="lazy"
                      src={imageBase64}
                      alt="prod-img"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                );
              })}
          </Slider>
        </div> */}
      </div>
      {showDetailOrder && <DetailOrder id="order" />}
      <ContactWays />
      <ContactWays />
      <HomeFooter />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
    allPopupPageRedux: state.popup.allPopupPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SaveToOrder: (cartItems) => dispatch(actions.SaveToOrder(cartItems)),
    addToOrder: (orderDetails) => dispatch(actions.addToOrder(orderDetails)),

    loadAllPopupPage: () => dispatch(actions.fetchAllPopupPage()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailCart)
);
