import React, { Component } from "react";
import { connect } from "react-redux";
import "./HomePage.scss";
import * as actions from "../../store/actions";
import { withRouter } from "react-router-dom";
import bocongthuong from "../../assets/bocongthuong.jpg";
import iso from "../../assets/iso.jpg";

class LastFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let inYear = new Date().getFullYear();

    return (
      <React.Fragment>
        <div className="container-fluid copyrights-container d-flex">
          <div className="business-check text-left d-flex flex-column">
            <h6>
              Copyright &copy; 2023 - {inYear} &nbsp;
              <b>CÔNG TY TNHH GIẤY VI TÍNH LIÊN SƠN.</b>
            </h6>
            <h6>
              Giấy chứng nhận đăng ký kinh doanh số: 0301452923, cấp ngày:
              24/11/1993 bởi Sở Kế hoạch và Đầu tư TP. Hồ Chí Minh.
            </h6>
            <h6>
              Địa chỉ đăng ký trụ sở chính: 34 Nguyễn Bỉnh Khiêm, P. ĐaKao, quận
              1, TP. Hồ Chí Minh
            </h6>
          </div>
          
          <div className="gov-check">
            <a href="http://online.gov.vn/Home/WebDetails/114042" target="_blank">
              <img
                alt=""
                title=""
                src={bocongthuong}
              />
            </a>
            
             <a href="https://vca.net.vn/tra-cuu-chung-chi/?scc=781" target="_blank" >
              <img
                alt=""
                title=""
                src={iso}
              />
            </a>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LastFooter);