import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import "./DetailOrder.scss";
import * as actions from "../../../store/actions";
import logo from "../../../assets/LOGO LIEN SON.png";
import CurrencyFormat from "react-currency-format";
import { withRouter, useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import { sendOrderEmail, sendOrderEmailAdmin } from "../../../services/userService";

const DetailOrder = () => {
  const orderDetails = useSelector((state) => state.cart.orderDetails);
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString("vi-VN")
  );
  
  const [imgCaptured, setImgCaptured] = useState(false);

  const checkType = (value) => {
    if (value === "cuộn") {
      return true;
    } else if (value === "photo") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!imgCaptured) {
      handleCaptureOrder();
    }
  }, [imgCaptured]);

  async function handleSendEmail(orderImg) {
    if (orderImg !== null && !imgCaptured) {
      // Add condition to check if image is not captured yet
      await sendOrderEmail({
        customerEmail: orderDetails.customerEmail,
        name: orderDetails.customerName,
        phone: orderDetails.customerPhone,
        orderNumber: orderDetails.generatedOrderNumber,
        orderImg: orderImg,
      });
      setImgCaptured(true); // Set imgCaptured to true after sending email
    }
  }
  
  async function handleSendEmailAdmin(orderImg) {
    if (orderImg !== null && !imgCaptured) {
      // Add condition to check if image is not captured yet
      await sendOrderEmailAdmin({
        customerEmail: orderDetails.customerEmail,
        name: orderDetails.customerName,
        phone: orderDetails.customerPhone,
        orderNumber: orderDetails.generatedOrderNumber,
        orderImg: orderImg,
      });
      setImgCaptured(true); // Set imgCaptured to true after sending email
    }
  }

  function handleCaptureOrder() {
    const orderSummaryElement = document.getElementById("order");

    html2canvas(orderSummaryElement).then((canvas) => {
      // Convert the canvas to a data URL representing the image
      const dataUrl = canvas.toDataURL("image/png");

      // Call the handleSendEmail function and pass the data URL as an argument
      handleSendEmail(dataUrl);
      handleSendEmailAdmin(dataUrl);
      setImgCaptured(true);
    });
  }

  function returnHome() {
    history.push(`/trang-chu`);
  }

  return (
    <>
      <div>
        <div
          className="container-fluid d-flex flex-column mt-2 border border-dark"
          style={{ maxWidth: "950px" }}
          id="order"
        >
          <div className="d-flex border-bottom border-dark order-header">
            <div className="d-flex justify-content-left content-left">
              <div className="co-logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="d-flex flex-column justify-content-around ml-2 ">
                <h5
                  className="font-weight-bold co-name"
                  style={{ color: "#354b9c" }}
                >
                  CÔNG TY TNHH GIẤY VI TÍNH LIÊN SƠN
                </h5>
                <h6>Đ/c: 34 Nguyễn Bỉnh Khiêm, P.Đa Kao, Q.1, TP.HCM</h6>
                <h6>Điện thoại: (028) 39.100.555 - Hotline: 0903.539.536</h6>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-around content-right">
              <h4
                className="font-weight-bold text-center"
                style={{ color: "#354b9c" }}
              >
                HÓA ĐƠN TẠM TÍNH
              </h4>
              <h6 className="text-center">Ngày đặt hàng: {currentDate}</h6>
              <h6 className="text-center font-weight-bold">
                Mã hóa đơn: {orderDetails.generatedOrderNumber}
              </h6>
            </div>
          </div>
          {orderDetails.selectedOption === "Có" && (
            <div className="border-bottom border-dark export-info">
              <div className="row text-danger d-flex">
                <div className="col-8">
                  <h5 className="font-weight-bold">THÔNG TIN XUẤT HÓA ĐƠN</h5>
                </div>
                <div className="col-4 small">
                  <h6 className="font-weight-bold">
                    Xuất hóa đơn VAT: {orderDetails.selectedOption}
                  </h6>
                </div>
              </div>
              <div className="row">
                <div class="col-8">
                  <h6>Tên công ty: <span>{orderDetails.customerCo}</span></h6>
                </div>
                <div className="col-4">
                  <h6>MST: {orderDetails.customerTax}</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h6>Đ/c công ty: {orderDetails.customerCoAddress}</h6>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex flex-column cust-info">
            <div className="border-bottom border-dark">
              <div className="row">
                <div class="col-8">
                  <h6>Tên khách hàng: <span>{orderDetails.customerName}</span></h6>
                </div>
                <div className="col-4">
                  <h6>SĐT: {orderDetails.customerPhone}</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h6>Đ/c giao hàng: {orderDetails.receiveAddress}</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  {orderDetails.selectedMethod === "Tiền mặt" && (
                    <div>
                      <h6>
                        Phương thức thanh toán: {orderDetails.selectedMethod}
                      </h6>
                      <div className="text-primary small">
                        (Quý khách thanh toán cho người vận chuyển sau khi nhận
                        và kiểm tra hàng)
                      </div>
                    </div>
                  )}

                  {orderDetails.selectedMethod === "Chuyển khoản" && (
                    <div>
                      <h6>
                        Phương thức thanh toán: {orderDetails.selectedMethod}
                      </h6>
                      <div className="text-primary small">
                        (Quý khách thanh toán theo số tài khoản 037-1001-888888
                        NH Vietcombank - CN Tân Định)
                        <br />
                        (Cú pháp "Số điện thoại _ Mã hóa đơn")
                      </div>
                    </div>
                  )}
                  
                  {orderDetails.selectedOption === "Không" && (
                    <div className="col-12 m-0 text-danger">
                        Xuất hóa đơn VAT: {orderDetails.selectedOption}
                    </div>
                  )}
                </div>
                
                <div className="col-4">
                <h6>E-mail: {orderDetails.customerEmail}</h6>
                </div>
                
              </div>
            </div>
            <table className="table-borderless table-sm">
              <tbody className="border-bottom border-dark order-detail">
                 <tr className="text-center border-bottom border-dark">
                  <th className="col-1">STT</th>
                  <th className="col-6">Tên sản phẩm</th>
                  <th className="col-1">SL</th>
                  <th className="col-1">ĐVT</th>
                  <th className="col-1 text-right">Đơn giá</th>
                  <th className="col-2 text-right">Thành tiền</th>
                </tr>

                {Array.from({ length: 20 }).map((_, index) => {
                  const item =
                    orderDetails.orderItem && orderDetails.orderItem[index];
                  if (item) {
                    const calType =
                      item.type === "cuộn"
                        ? "Cuộn"
                        : item.type === "đllt"
                        ? "Thùng"
                        : "Ream";
                    return (
                      <tr key={index} className="text-center">
                        <th scope="row" className="col-1">
                          {index + 1}
                        </th>
                        {checkType(item.type) && (
                          <td className="text-left col-6">{item.name}</td>
                        )}
                        {!checkType(item.type) && (
                          <td className="text-left col-6">
                            {item.name}({item.form})
                          </td>
                        )}
                        <td className="col-1">{item.amount}</td>
                        <td className="col-1">{calType}</td>
                        <td className="text-right col-1">
                          <CurrencyFormat
                            value={item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right col-2">
                          <CurrencyFormat
                            value={item.total}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={index}>
                        <td className="blank"></td>
                      </tr>
                    );
                  }
                })}
              </tbody>

              <tbody className="border-bottom border-dark order-price">
                <tr className="text-center">
                  <td></td>
                  <td className="text-right" scope="col">
                    Tổng:
                  </td>
                  <td scope="col">{orderDetails.totalAmount}</td>
                  <td colSpan={2}></td>
                  <td className="text-right" scope="col">
                    <CurrencyFormat
                      value={orderDetails.preTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                
                {orderDetails.discount !== 0 && (
                  <tr>
                    <td></td>
                    <td className="text-right text-danger">Chiết khấu:</td>
                    <td colSpan={3}></td>
                    <td className="text-right text-danger">
                      <span>-</span>
                      <CurrencyFormat
                        value={orderDetails.discount}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                )}
                
                <tr className="text-center">
                  <td></td>
                  <td className="text-right">VAT(8%):</td>
                  <td colSpan={3}></td>
                  <td className="text-right">
                    <CurrencyFormat
                      value={orderDetails.vat}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                
                <tr className="text-center">
                  <td></td>
                  <td className="text-right">Tổng tiền hàng:</td>
                  <td colSpan={3}></td>
                  <td className="text-right">
                    <CurrencyFormat
                      value={orderDetails.preTotal + orderDetails.vat - orderDetails.discount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td className="text-right">Phí giao hàng:</td>
                  <td colSpan={3}></td>
                  <td className="text-right">
                    <CurrencyFormat
                      value={orderDetails.shipFee}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="text-right small text-danger" colSpan={6}>
                    (Ngoài bán kính giao hàng 5km, chúng tôi sẽ liên lạc báo phí giao hàng)
                  </td>
                </tr>

                {/* {orderDetails.couponDiscount !== 0 && ( */}
                <tr>
                  <td></td>
                  <td className="text-right text-danger">Mã giảm giá:</td>
                  <td colSpan={3}></td>
                  <td className="text-right text-danger">
                    <span>-</span>
                    <CurrencyFormat
                      value={orderDetails.couponDiscount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                {/* )} */}

                <tr>
                  <td></td>
                  <td className="text-right text-uppercase">
                    <h5 className="font-weight-bold">Tổng tiền thanh toán:</h5>
                  </td>
                  <td colSpan={2}></td>
                  <td className="text-right" colSpan={2}>
                    <h5 className="font-weight-bold">
                      <CurrencyFormat
                        value={orderDetails.finalTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                        className="total-value"
                      />
                      &nbsp; VNĐ
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-center">
              <div className="text-uppercase">
                <h5 className="font-weight-bold">
                  Xin cảm ơn quý khách đã mua hàng
                </h5>
              </div>
              <div className="caution small">
                (Trong vòng 3 ngày từ ngày nhận được hàng, quý khách không phản hồi về sản phẩm, chúng tôi tiến hành xuất hóa đơn VAT, sản phẩm sẽ không được đổi trả lại)
              </div>
              <div className="caution small font-weight-bold">
                Đơn hàng sẽ được vận chuyển đến quý khách từ 1 đến 3 ngày sau khi chúng tôi gọi điện xác nhận đơn hàng
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <div className="container-fluid d-flex justify-content-end option">
        <button
          type="button"
          className="btn btn-dark"
          onClick={() => window.print()}
        >
          In hóa đơn
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => returnHome()}
        >
          Trở về trang chủ
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orderDetails: state.cart.orderDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToOrder: (orderDetails, orderProducts) =>
      dispatch(actions.addToOrder(orderDetails, orderProducts)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailOrder)
);
