import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import "./TableManageOrder.scss";
import CurrencyFormat from "react-currency-format";
import logo from "../../../../assets/LOGO LIEN SON.png";

class DetailOrderAdminEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: this.props.item,
      arrDetail: [],

      orderNumber: "",
      customerName: "",
      companyName: "",
      companyAddress: "",
      taxNumber: "",
      phoneNumber: "",
      receiveAddress: "",
      payment: "",
      emailAddress: "",
      notify: "",
      
      preTotal: 0,
      totalPrice: 0,
      shipFee: 0,
      couponDiscount: 0,
      discount: 0,
      vat: 0,
      date: "",
      
      vatInputChanged: false,
    };
  }
  componentDidMount() {
    this.props.fetchOrderRedux();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Assuming this.props.listDetail is an array of products with value and form properties
    if (prevProps.listDetail !== this.props.listDetail) {
      this.setState({ arrDetail: this.props.listDetail });
    }

    if (prevProps.item !== this.props.item) {
      this.setState({
        customerName: this.props.item.fullName,
        phoneNumber: this.props.item.phoneNumber,
        receiveAddress: this.props.item.receiveAddress,
        companyName: this.props.item.companyName,
        companyAddress: this.props.item.companyAddress,
        taxNumber: this.props.item.taxNumber,
        emailAddress: this.props.item.emailAddress,
        notify: this.props.item.notify,

        preTotal: this.props.item.preTotal,
        totalPrice: this.props.item.totalPrice,
        shipFee: this.props.item.shipFee,
        couponDiscount: this.props.item.couponDiscount,
        discount: this.props.item.discount,
        vat: this.props.item.vat,
        orderNumber: this.props.item.orderNumber,
        date: this.props.item.date,

        orderNumber: this.props.item.orderNumber,
        billPrinted: this.props.item.billPrinted,
        payment: this.props.item.payment,
      });
    }
  }

  componentDidMount() {
    document.body.addEventListener("keydown", this.handleEsc); // Add event listener for "keydown" on the document body
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleEsc); // Remove event listener when component is unmounted
  }

  checkRoll = (value) => {
    if (value === "cuộn") {
      return true;
    } else {
      return false;
    }
  };

  handleClose = (e) => {
    this.props.handleCloseEditFromParent();
  };

  handleEsc = (e) => {
    if (e.key === "Escape") {
      this.props.handleCloseEditFromParent();
    }
  };

  incNum = (index) => {
    const updatedAmount = [...this.state.arrDetail];
    updatedAmount[index].amount += 1;
    this.setState({ arrDetail: updatedAmount });
  };

  decNum = (index) => {
    const updatedAmount = [...this.state.arrDetail];
    if (updatedAmount[index].amount > 1) {
      updatedAmount[index].amount -= 1;
      this.setState({ arrDetail: updatedAmount });
    }
  };

  handleChange = (index, e) => {
    const updatedArr = [...this.state.arrDetail];
    updatedArr[index].amount = e.target.value ? parseInt(e.target.value) : "";
    updatedArr[index].form = e.target.value;
    this.setState({ arrDetail: updatedArr });
  };

  handleChangeForm = (index, e) => {
    const updatedArr = [...this.state.arrDetail];
    updatedArr[index].form = e.target.value;
    this.setState({ arrDetail: updatedArr });
  };
  
  handleChangePayment = (e) => {
    let copyState = { ...this.state };
    copyState.payment = e.target.value;
    this.setState({
      ...copyState,
    });
  };
  
  handleChangeBillPrinted = (e) => {
    let copyState = { ...this.state };
    copyState.billPrinted = e.target.value;
    this.setState({
      ...copyState,
    });
  };

  onChangeInput = (e, id) => {
    let copyState = { ...this.state };
    copyState[id] = e.target.value;
    this.setState({
      ...copyState,
    });
  };
  
  onChangeShipFee = (e) => {
    let copyState = { ...this.state };
    const value = e.target.value.trim(); // Remove leading and trailing whitespaces
    copyState.shipFee = value === "" ? "" : parseInt(value);
    this.setState({
      ...copyState,
    });
  };
  
  onChangeVat = (e) => {
    const value = e.target.value.trim();
    this.setState({
      vat: value === "" ? 0 : parseInt(value),
      vatInputChanged: true,
    });
  };

  handleRecalculate = () => {
    const { preTotal, totalPrice, shipFee, couponDiscount, discount, vat ,vatInputChanged } =
      this.state;
    // const newPreTotal = this.state.arrDetail
    //   .map((item) => {
    //     return item.amount * item.price;
    //   })
    //   .reduce((a, b) => a + b, 0);
    const newPreTotal = this.state.arrDetail
      .filter((item) => item.isDelete !== "true") // Filter out deleted items
      .map((item) => item.amount * item.price)
      .reduce((a, b) => a + b, 0);
    const newVat = vatInputChanged ? this.state.vat : (newPreTotal * 8) / 100;
    const newTotalPrice =
      couponDiscount !== 0
        ? preTotal + vat + shipFee - discount - couponDiscount
        : preTotal + vat + shipFee - discount;
    // const newShipFee =
    //   preTotal < 10000000 ? 35000 : preTotal < 20000000 ? 20000 : 0;
    const newDiscount =
      20000000 <= preTotal
        ? preTotal * (5 / 100)
        : 10000000 <= preTotal
        ? preTotal * (4 / 100)
        : 5000000 <= preTotal
        ? preTotal * (2 / 100)
        : 0;
    const newCouponDiscount =
      couponDiscount === 0
        ? 0
        : preTotal < 1000000
        ? 0
        : preTotal > 1000000 && couponDiscount !== 0
        ? shipFee
        : 0;

    if (newPreTotal !== preTotal) {
      this.setState({ preTotal: newPreTotal });
    }
    if (newVat !== vat) {
      this.setState({ vat: newVat });
    }
    if (newDiscount !== discount) {
      this.setState({ discount: newDiscount });
    }
    if (newCouponDiscount !== couponDiscount) {
      this.setState({ couponDiscount: newCouponDiscount });
    }
    if (newTotalPrice !== totalPrice) {
      this.setState({ totalPrice: newTotalPrice });
    }
  };

  handleDeleteProduct = (index) => {
    const updatedArr = [...this.state.arrDetail];
    if (index > updatedArr.length) {
      index -= 1;
    }
    updatedArr[index].isDelete = "true";
    updatedArr[index].amount = 0;
    this.setState({ arrDetail: updatedArr });
  };

  handleEditOrder = async () => {
    await this.props.editOrder({
      orderNumber: this.state.orderNumber,

      customerName: this.state.customerName,
      taxNumber: this.state.taxNumber,
      preTotal: this.state.preTotal,
      discount: this.state.discount,
      couponDiscount: this.state.couponDiscount,
      vat: this.state.vat,
      shipFee: this.state.shipFee,
      totalPrice: this.state.totalPrice,
      receiveAddress: this.state.receiveAddress,
      companyName: this.state.companyName,
      companyAddress: this.state.companyAddress,
      phoneNumber: this.state.phoneNumber,
      taxNumber: this.state.taxNumber,
      payment: this.state.payment,
      billPrinted: this.state.billPrinted,
      emailAddress: this.state.emailAddress,
      notify: this.state.notify,
    });
  };

  handleEditOrderProduct = async () => {
    await this.props.editOrderProduct({
      arrDetail: this.state.arrDetail,
    });
  };
  render() {
    let arrDetail = this.state.arrDetail;
    const totalAmount = arrDetail.reduce(
      (total, item) => total + item.amount,
      0
    );
    let {
      customerName,
      companyName,
      companyAddress,
      taxNumber,
      phoneNumber,
      receiveAddress,
      preTotal,
      totalPrice,
      shipFee,
      couponDiscount,
      discount,
      vat,
      date,
      orderNumber,
      billPrinted,
      payment,
      emailAddress,
      notify,
    } = this.state;
    return (
      <React.Fragment>
        <div className="detail-order-container" id="order-admin">
          <div className="order-container">
            <div
              className="container-fluid d-flex flex-column mt-2 border border-dark order"
              style={{ maxWidth: "950px" }}
            >
              <div className="d-flex border-bottom border-dark order-header">
                <div className="d-flex justify-content-left content-left">
                  <div className="co-logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="d-flex flex-column justify-content-around ml-2 ">
                    <h5
                      className="font-weight-bold co-name"
                      style={{ color: "#354b9c" }}
                    >
                      CÔNG TY TNHH GIẤY VI TÍNH LIÊN SƠN
                    </h5>
                    <h6>Đ/c: 34 Nguyễn Bỉnh Khiêm, P.Đa Kao, Q.1, TP.HCM</h6>
                    <h6>
                      Điện thoại: (028) 39.100.555 - Hotline: 0903.539.536
                    </h6>
                  </div>
                </div>

                <div className="d-flex flex-column justify-content-around content-right">
                  <h4
                    className="font-weight-bold text-center"
                    style={{ color: "#354b9c" }}
                  >
                    HÓA ĐƠN TẠM TÍNH
                  </h4>
                  <h6 className="text-center">Ngày: {date}</h6>
                  <h6 className="text-center font-weight-bold">
                    Mã đơn hàng: {orderNumber}
                  </h6>
                </div>
              </div>
              {billPrinted === "Có" && (
                <div className="border-bottom border-dark export-info">
                  <div className="row text-danger d-flex">
                    <div className="col-8">
                      <h5 className="font-weight-bold">
                        THÔNG TIN XUẤT HÓA ĐƠN
                      </h5>
                    </div>
                    <div className="col-4 small">
                      <h6 className="font-weight-bold">
                        Xuất hóa đơn VAT: <select
                            defaultValue={billPrinted}
                            onChange={(e) => this.handleChangeBillPrinted(e)}
                          >
                            <option value="Có">Có</option>
                            <option value="Không">Không</option>
                          </select>
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h6>
                        Tên công ty:
                        <input
                          className="form-control"
                          type="text"
                          value={companyName}
                          onChange={(e) => this.onChangeInput(e, "companyName")}
                        />
                      </h6>
                    </div>
                    <div className="col-4">
                      <h6>
                        MST:
                        <input
                          className="form-control"
                          type="text"
                          value={taxNumber}
                          onChange={(e) => this.onChangeInput(e, "taxNumber")}
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h6>
                        Đ/c công ty:
                        <input
                          className="form-control"
                          type="text"
                          value={companyAddress}
                          onChange={(e) =>
                            this.onChangeInput(e, "companyAddress")
                          }
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex flex-column cust-info">
                <div className="border-bottom border-dark">
                  <div className="row">
                    <div className="col-8">
                      <h6>
                        Tên người đặt hàng:
                        <input
                          className="form-control"
                          type="text"
                          value={customerName}
                          onChange={(e) =>
                            this.onChangeInput(e, "customerName")
                          }
                        />
                      </h6>
                    </div>
                    <div className="col-4">
                      <h6>
                        SĐT:
                        <input
                          className="form-control"
                          type="text"
                          value={phoneNumber}
                          onChange={(e) => this.onChangeInput(e, "phoneNumber")}
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h6>
                        Đ/c giao hàng:
                        <input
                          className="form-control"
                          type="text"
                          value={receiveAddress}
                          onChange={(e) =>
                            this.onChangeInput(e, "receiveAddress")
                          }
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      {payment === "Tiền mặt" && (
                        <div>
                          <h6>
                            Phương thức thanh toán:
                            <select
                              defaultValue={payment}
                              onChange={(e) => this.handleChangePayment(e)}
                            >
                              <option value="Tiền mặt">Tiền mặt</option>
                              <option value="Chuyển khoản">Chuyển khoản</option>
                            </select>
                          </h6>
                          <div className="text-primary small">
                            (Quý khách thanh toán cho người vận chuyển sau khi
                            nhận và kiểm tra hàng)
                          </div>
                        </div>
                      )}

                      {payment === "Chuyển khoản" && (
                        <div>
                          <h6>
                            Phương thức thanh toán:
                            <select
                              defaultValue={payment}
                              onChange={(e) => this.handleChangePayment(e)}
                            >
                              <option value="Tiền mặt">Tiền mặt</option>
                              <option value="Chuyển khoản">Chuyển khoản</option>
                            </select>
                          </h6>
                          <div className="text-primary small">
                            (Quý khách thanh toán theo số tài khoản
                            037-1001-888888 NH Vietcombank - CN Tân Định)
                            <br />
                            (Cú pháp "Số điện thoại _ Mã đơn hàng")
                          </div>
                        </div>
                      )}
                      
                      {billPrinted === "Không" && (
                      <div className="col-12 text-danger">
                        Xuất hóa đơn VAT: <select
                            defaultValue={billPrinted}
                            onChange={(e) => this.handleChangeBillPrinted(e)}
                          >
                            <option value="Có">Có</option>
                            <option value="Không">Không</option>
                          </select>
                      </div>
                      )}
                    </div>
                    
                    <div className="col-4 text-left">
                      <h6>
                        Tên người đặt hàng:
                        <input
                          className="form-control"
                          type="text"
                          value={emailAddress}
                          onChange={(e) =>
                            this.onChangeInput(e, "emailAddress")
                          }
                        />
                      </h6>
                    </div>
                    
                  </div>
                </div>
                <table className="table-borderless table-sm">
                  <tbody className="border-bottom border-dark order-detail">
                    <tr className="text-center border-bottom border-dark">
                      <th scope="col">STT</th>
                      <th scope="col">Tên sản phẩm</th>
                      <th scope="col">SL</th>
                      <th scope="col" className="text-right">
                        Đơn giá
                      </th>
                      <th scope="col" className="text-right">
                        Thành tiền
                      </th>
                    </tr>

                    {Array.isArray(arrDetail) &&
                      arrDetail.map((item, index) => {
                        if (item.isDelete !== "true") {
                          // Condition added here
                          return (
                            <tr key={index} className="text-center">
                              <th scope="row" className="col-1">
                                {index + 1}
                              </th>
                              {this.checkRoll(item.type) && (
                                <td className="text-left col-6">
                                  {item.productName}
                                </td>
                              )}
                              {!this.checkRoll(item.type) && (
                                <td className="text-left col-6">
                                  {item.productName} &nbsp;
                                  <select
                                    defaultValue={item.form}
                                    onChange={(e) =>
                                      this.handleChangeForm(index, e)
                                    }
                                  >
                                    <option value="không chia">
                                      Không chia
                                    </option>
                                    <option value="chia đôi">Chia đôi</option>
                                  </select>
                                </td>
                              )}
                              <td className="col-1 amount">
                                <span className="decrease">
                                  <button
                                    type="button"
                                    onClick={() => this.decNum(index)}
                                  >
                                    -
                                  </button>
                                </span>
                                <input
                                  type="number"
                                  className="amount-number"
                                  value={item.amount}
                                  placeholder="0"
                                  onChange={(e) => this.handleChange(index, e)}
                                />
                                <span className="increase">
                                  <button
                                    type="button"
                                    onClick={() => this.incNum(index)}
                                  >
                                    +
                                  </button>
                                </span>
                              </td>
                              <td className="text-right col-1">
                                <CurrencyFormat
                                  value={item.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                              <td className="text-right col-2">
                                <CurrencyFormat
                                  value={item.price * item.amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                              <td className="delete-btn col-1">
                                <button
                                  onClick={() =>
                                    this.handleDeleteProduct(index)
                                  }
                                >
                                  <i className="fas fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>

                  <tbody
                    className="border-bottom border-dark"
                    onChange={this.handleRecalculate()}
                  >
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right" scope="col">
                        Tổng:
                      </td>
                      <td scope="col">{totalAmount}</td>
                      <td></td>
                      <td className="text-right" scope="col">
                        <CurrencyFormat
                          value={preTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>
                    
                    {discount !== 0 && (
                      <tr>
                        <td></td>
                        <td className="text-right text-danger">Chiết khấu:</td>
                        <td></td>
                        <td></td>
                        <td className="text-right text-danger">
                          <span>-</span>
                          <CurrencyFormat
                            value={discount}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                    )}
                    
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right">VAT(8%):</td>
                      <td></td>
                      <td></td>
                      <td className="text-right">
                        <input
                          className="form-control text-right"
                          type="number"
                          value={vat}
                          onChange={(e) => this.onChangeVat(e)}
                        />
                      </td>
                    </tr>
                    
                    <tr className="text-center">
                      <td></td>
                      <td className="text-right">Tổng tiền hàng:</td>
                      <td></td>
                      <td></td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={preTotal + vat - discount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="text-right">Phí giao hàng:</td>
                      <td></td>
                      <td></td>
                      <td className="text-right">
                        <input
                          className="form-control text-right"
                          type="number"
                          value={shipFee}
                          onChange={(e) => this.onChangeShipFee(e)}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="text-right text-danger">Mã giảm giá:</td>
                      <td></td>
                      <td></td>
                      <td className="text-right text-danger">
                        <span>-</span>
                        <CurrencyFormat
                          value={couponDiscount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="text-right text-uppercase">
                        <h5 className="font-weight-bold">
                          Tổng tiền thanh toán:
                        </h5>
                      </td>
                      <td></td>
                      <td className="text-right" colSpan={2}>
                        <h5 className="font-weight-bold">
                          <CurrencyFormat
                            value={totalPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            className="total-value"
                          />
                          &nbsp; VNĐ
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-center">
                  <div className="text-uppercase">
                    <h5 className="font-weight-bold">
                      Xin cảm ơn quý khách đã mua hàng
                    </h5>
                  </div>
                  <div className="caution small">
                    (Trong vòng 3 ngày kể từ ngày nhận được hàng, nếu quý khách
                    không phản hồi về sản phẩm, chúng tôi tiến hành xuất hóa đơn
                    VAT và sản phẩm sẽ không được đổi trả lại)
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            
            <div
              className="container-fluid sign mb-1"
              style={{ maxWidth: "950px" }}
            >
              <div className="col">
                <p className="text-left">Ghi chú:</p>
                <div className="signspace">
                      <h6>
                        <input
                          className="form-control"
                          type="text"
                          value={notify}
                          onChange={(e) =>
                            this.onChangeInput(e, "notify")
                          }
                        />
                      </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-center option-admin"
          style={{ maxWidth: "790px" }}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => (
              this.handleEditOrder(),
              this.handleEditOrderProduct(),
              this.handleClose()
            )}
          >
            Cập nhật
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.handleClose()}
            onKeyDown={(e) => this.handleEsc(e)}
          >
            Đóng
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listOrders: state.order.orders,
    listDetail: state.order.detailOrders,
    detailUser: state.order.userName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderRedux: () => dispatch(actions.fetchOrderStart()),
    detailByNumber: (orderNumber) =>
      dispatch(actions.detailByNumber(orderNumber)),
    editOrder: (orderInfo) => dispatch(actions.editOrderStart(orderInfo)),
    editOrderProduct: (arrDetail) =>
      dispatch(actions.editOrderProductStart(arrDetail)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailOrderAdminEdit);
