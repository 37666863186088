import actionTypes from "./actionTypes";
import {
  deleteOrder,
  getDetailOrder,
  getOrder,
  getUserByOrderNumber,
  editOrderStatus,
  editOrder,
  editOrderProduct,
  checkNewOrder,
  searchOrder,
} from "../../services/orderService";
import { toast } from "react-toastify";
import { getAllCodeService } from "../../services/productService";

export const fetchOrderStart = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getOrder("ALL");
      if (res && res.errCode === 0) {
        dispatch(fetchOrderSuccess(res.orders));
        // dispatch(checkNewOrderFunc());
      } else {
        toast.error("Lấy đơn hàng thất bại");
        dispatch(fetchOrderFailed());
      }
    } catch (e) {
      toast.error("Lấy đơn hàng thất bại");
      dispatch(fetchOrderFailed());
      console.log("fetchOrderFailed error:", e);
    }
  };
};

export const fetchOrderSuccess = (data) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_ORDER_SUCCESS,
    orders: data,
  });
};

export const fetchOrderFailed = () => ({
  type: actionTypes.FETCH_ORDER_FAILED,
});

export const deleteOrderStart = (orderInfo) => {
  return async (dispatch, getState) => {
    try {
      let res = await deleteOrder(orderInfo);
      console.log(res);
      if (res && res.errCode === 0) {
        dispatch(deleteOrderSuccess(res.orders));
        toast.success("Xóa thành công");
        dispatch(fetchOrderStart());
      } else {
        toast.error("Xóa đơn hàng thất bại");
        dispatch(deleteOrderFailed());
      }
    } catch (e) {
      toast.success("Xóa đơn hàng thất bại");
      dispatch(fetchOrderFailed());
      console.log("fetchOrderFailed error:", e);
    }
  };
};

export const deleteOrderSuccess = (data) => ({
  type: actionTypes.DELETE_ORDER_SUCCESS,
});

export const deleteOrderFailed = () => ({
  type: actionTypes.DELETE_ORDER_FAILED,
});

export const editOrderStart = (orderInfo) => {
  return async (dispatch, getState) => {
    try {
      let res = await editOrder(orderInfo);
      if (res && res.errCode === 0) {
        toast.success("Cập nhật đơn hàng thành công");
        dispatch(editOrderSuccess());
        dispatch(fetchOrderStart());
      } else {
        toast.error("Cập nhật đơn hàng thất bại");
        dispatch(editOrderFailed());
      }
    } catch (e) {
      toast.error("Cập nhật đơn hàng thất bại");
      dispatch(editOrderFailed());
      console.log("Edit order error", e);
    }
  };
};

export const editOrderSuccess = (orderInfo) => ({
  type: actionTypes.EDIT_ORDER_SUCCESS,
  orderInfo: orderInfo,
});

export const editOrderFailed = () => ({
  type: actionTypes.EDIT_ORDER_FAILED,
});

export const editOrderProductStart = (arrDetail) => {
  return async (dispatch, getState) => {
    try {
      let res = await editOrderProduct(arrDetail);
      if (res && res.errCode === 0) {
        toast.success("Cập nhật sản phẩm đơn hàng thành công");
        dispatch(editOrderProductSuccess());
        dispatch(fetchOrderStart());
      } else {
        toast.error("Cập nhật sản phẩm đơn hàng thất bại");
        dispatch(editOrderProductFailed());
      }
    } catch (e) {
      toast.error("Cập nhật sản phẩm đơn hàng thất bại");
      dispatch(editOrderProductFailed());
      console.log("Edit order product error", e);
    }
  };
};

export const editOrderProductSuccess = () => ({
  type: actionTypes.EDIT_ORDER_PRODUCT_SUCCESS,
});

export const editOrderProductFailed = () => ({
  type: actionTypes.EDIT_ORDER_PRODUCT_FAILED,
});

export const detailByNumber = (orderNumber) => {
  return async (dispatch, getState) => {
    try {
      let res = await getDetailOrder(orderNumber);
      if (res && res.errCode === 0) {
        dispatch(detailByNumberSuccess(res.data));
      } else {
        toast.success("Lấy đơn hàng thất bại");
        dispatch(detailByNumberFailed());
      }
    } catch (e) {
      toast.success("Lấy đơn hàng thất bại");
      dispatch(detailByNumberFailed());
      console.log("fetchOrderFailed error:", e);
    }
  };
};

export const detailByNumberSuccess = (data) => ({
  type: actionTypes.DETAIL_BY_ORDER_SUCCESS,
  detail: data,
});

export const detailByNumberFailed = () => ({
  type: actionTypes.DETAIL_BY_ORDER_FAILED,
});

export const fetchStatusStart = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllCodeService("STATUS");
      // console.log(res)
      if (res && res.errCode === 0) {
        dispatch(fetchStatusSuccess(res.data));
      } else {
        dispatch(fetchStatusFailed());
      }
    } catch (e) {
      dispatch(fetchStatusFailed());
      console.log("fetchStatusStart error", e);
    }
  };
};

export const fetchStatusSuccess = (statusData) => ({
  type: actionTypes.FETCH_STATUS_SUCCESS,
  data: statusData,
});

export const fetchStatusFailed = () => ({
  type: actionTypes.FETCH_STATUS_FAILED,
});

export const editOrderStatusFunc = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await editOrderStatus(data);
      if (res && res.errCode === 0) {
        toast.success("Cập nhật trạng thái thành công");
        dispatch(editOrderStatusSuccess(data));
        dispatch(fetchStatusStart());
      } else {
        toast.error("Cập nhật trạng thái thất bại");
        dispatch(editOrderStatusFailed());
      }
    } catch (e) {
      toast.error("Cập nhật trạng thái thất bại");
      dispatch(editOrderStatusFailed());
      console.log("editProductFailed error", e);
    }
  };
};

export const editOrderStatusSuccess = (data) => ({
  type: actionTypes.EDIT_ORDER_STATUS_SUCCESS,
  payload: data,
});

export const editOrderStatusFailed = () => ({
  type: actionTypes.EDIT_ORDER_STATUS_FAILED,
});

export const checkNewOrderFunc = () => {
  return async (dispatch, getState) => {
    try {
      let res = await checkNewOrder();
      if (res && res.errCode === 0) {
        if (res.newOrder > 0) {
          dispatch(fetchOrderStart());
        }
        dispatch(checkNewOrderSuccess(res.newOrder));
      } else {
        toast.error("Không có đơn hàng mới");
        dispatch(fetchOrderFailed());
      }
    } catch (e) {
      toast.error("Lấy đơn hàng thất bại");
      dispatch(checkNewOrderFailed());
      console.log("fetchOrderFailed error:", e);
    }
  };
};

export const checkNewOrderSuccess = (data) => ({
  type: actionTypes.CHECK_NEW_ORDER_SUCCESS,
  payload: data,
});

export const checkNewOrderFailed = () => ({
  type: actionTypes.CHECK_NEW_ORDER_FAILED,
});

export const searchOrderStart = (orderNumber) => {
  return async (dispatch) => {
    try {
      let res = await searchOrder(orderNumber);
      if (res && res.errCode === 0) {
        dispatch({
          type: actionTypes.SEARCH_ORDER_SUCCESS,
          dataOrderList: res.data,
        });
      } else {
        dispatch({
          type: actionTypes.SEARCH_ORDER_FAILED,
        });
      }
    } catch (e) {
      dispatch({
        type: actionTypes.SEARCH_ORDER_FAILED,
      });
    }
  };
};
