import actionTypes from "../actions/actionTypes";

const initialState = {
  popups: [],
  allPopupPage: [],
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_POPUP_SUCCESS:
      state.popups = action.popups;
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_POPUP_FAILED:
      state.popups = [];
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_POPUP_PAGE_SUCCESS:
      state.allPopupPage = action.dataAllPopup;
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_POPUP_PAGE_FAILED:
      state.allPopupPage = [];
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default popupReducer;
