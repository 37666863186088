import React, { Component } from "react";
import { connect } from "react-redux";
import HomeHeader from "../../HomePage/HomeHeader";
import Tab from "../../HomePage/Section/Tab";
import HomeFooter from "../../HomePage/HomeFooter";
import "./DetailPrinted.scss";
import { getDetailInforProduct } from "../../../services/productService";
import { toast } from "react-toastify";
import {
  sendRequestContactPrinted,
  sendRequestContactProduct,
} from "../../../services/userService";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import * as actions from "../../../store/actions";
import "react-image-lightbox/style.css";
import { CommonUtils } from "../../../utils";
import PopupForm from "./PopupForm";
import ContactOrders from "../../HomePage/Section/ContactOrders";
import ContactWays from "../../HomePage/Section/ContactWays";

class DetailPrinted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DetailPrinted: {},
      mainImg: "",

      phone: "",
    };
  }

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      let id = this.props.match.params.id;
      let res = await getDetailInforProduct(id);
      if (res && res.errCode === 0) {
        this.setState({
          DetailPrinted: res.data,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevStat, snapshot) {
    if (this.props.match && this.props.match.params) {
      const { id: prevId, name: prevName } = prevProps.match.params;
      const { id, name } = this.props.match.params;

      // Check if the product ID or name has changed
      if (id !== prevId || name !== prevName) {
        this.setState({
          DetailPrinted: this.fetchProductDetails(id),
          mainImg: "",
        });
      }
    }
  }

  async fetchProductDetails(id, name) {
    let res = await getDetailInforProduct(id);
    if (res && res.errCode === 0) {
      this.setState({
        DetailPrinted: res.data,
      });
    }
  }

  handleChangeImage = (e) => {
    this.setState({
      mainImg: e.target.src,
    });
  };

  handleOnChangeImage = async (event) => {
    let data = event.target.files;
    let file = data[0];
    if (file) {
      let base64 = await CommonUtils.getBase64(file);
      let objectUrl = URL.createObjectURL(file);
      this.setState({
        previewImgUrl: objectUrl,
        image: base64,
      });
    }
  };

  handleSendPhoneEmail = async () => {
    let res = await sendRequestContactPrinted({
      phone: this.state.phone,
      type: this.state.DetailPrinted.type,
      productName: this.state.DetailPrinted.name,
    });

    if (res && res.errCode === 0) {
      toast.success("Yêu cầu đã gửi");
    } else {
      toast.error(`Vui lòng nhập số điện thoại để chúng tôi gọi lại quý khách`);
    }
  };

  onChangeInput = (event, id) => {
    let copyState = { ...this.state };
    copyState[id] = event.target.value;
    this.setState({
      ...copyState,
    });
  };

  render() {
    let { phone } = this.state;
    let { mainImg } = this.state;
    let { DetailPrinted } = this.state;

    return (
      <>
        <HomeHeader />
        <Tab />
        <div className="printed-detail-container">
          <div className="printed-image">
            <div className="printed-image-main">
              {mainImg !== "" && <img src={mainImg} alt="mainImg" />}
              {mainImg === "" && (
                <img src={DetailPrinted.image} alt="mainImg" />
              )}
            </div>
            <div className="printed-image-option">
              <div className="option-image">
                <img
                  src={DetailPrinted.image}
                  alt="firstImg"
                  onClick={(e) => this.handleChangeImage(e)}
                />
              </div>

              {DetailPrinted.secondImage && (
                <div className="option-image">
                  <img
                    src={DetailPrinted.secondImage}
                    alt="secondImg"
                    onClick={(e) => this.handleChangeImage(e)}
                  />
                </div>
              )}

              {DetailPrinted.thirdImage && (
                <div className="option-image">
                  <img
                    src={DetailPrinted.thirdImage}
                    alt="thirdImg"
                    onClick={(e) => this.handleChangeImage(e)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="printed-detail">
            <div className="printed-info">
              <div className="printed-name">{DetailPrinted.name}</div>
              <div className="printed-price">
                <div className="price-number">{DetailPrinted.describe}</div>
              </div>

              <div className="printed-describe">
                {DetailPrinted &&
                  DetailPrinted.Markdown &&
                  DetailPrinted.Markdown.contentHTML && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DetailPrinted.Markdown.contentHTML,
                      }}
                    ></div>
                  )}
              </div>
            </div>

            <div className="popup-form">
              <PopupForm
                productName={DetailPrinted.name}
                productType={DetailPrinted.type}
              />
            </div>

            <div className="request-contact">
              <div className="request-form">
                <input
                  type="tel"
                  placeholder="Nhập số điện thoại để chúng tôi gọi lại"
                  pattern="[0-9]{10}"
                  required
                  value={phone}
                  onChange={(event) => {
                    this.onChangeInput(event, "phone");
                  }}
                ></input>
              </div>

              <button
                className="request-button"
                onClick={() => {
                  this.handleSendPhoneEmail();
                  this.setState({ phone: "" });
                }}
              >
                Gửi yêu cầu
              </button>
            </div>
          </div>
        </div>
        {DetailPrinted.type === "đl5l" && <ContactWays />}
        {DetailPrinted.type !== "đl5l" && <ContactOrders />}
        <HomeFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DetailPrinted: state.detailProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (cartItem) => dispatch(actions.addToCart(cartItem)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailPrinted)
);
