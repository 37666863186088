import React from "react";
import "./SearchResultList.scss";
import { SearchResult } from "./SearchResult";

export const SearchResultList = ({ results, setResults }) => {
  return (
    <>
      {results && results.length > 0 && (
        <div className="result-list">
          {results.map((result, id) => {
            return <SearchResult result={result} setResult={setResults} key={id} />;
          })}
        </div>
      )}
    </>
  );
};
